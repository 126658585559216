/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState , useRef } from 'react'
import { CircularProgress, Divider, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import { ArrowBack, Delete, Save, Visibility } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as quotesDUCK from "../../../redux/duck/quotes.duck"
import * as appDuck from '../../../redux/duck/app.duck';
import { setAlert } from '../../../redux/duck/alert.duck'
import { getQuote, updateQuote, updateFiles, deleteQuoteFile, updateViewedQuote } from '../../crud/api/quotes.crud'

const SingleQuote = ({ quote, quoteActions, appActions, setAlert }) => {
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));

  const history = useHistory()
  const [quoteData, setQuoteData] = useState(quote)
  const [loading, setLoading] = useState(false)
  const [file_loading, setFileLoading] = useState(false)
  const file = useRef();

  let uuid = history.location.pathname.includes("edit") && history.location.pathname.slice(8, -5)

  useEffect(() => {
    if (uuid && !quote.uuid) {
      updateViewedQuote(quoteActions.updateViewedQuote,uuid).then(() => 
        getQuote(setQuoteData, uuid)
      )     
    }else {
      updateViewedQuote(quoteActions.updateViewedQuote,uuid)
    }
  }, [quote, quoteActions.updateViewedQuote, uuid])

  const handleChange = e => setQuoteData({...quoteData, [e.target.name]: e.target.value})

  const update = () => {
    setLoading(true)
    setTimeout(() => {
      updateQuote(quoteActions.updateQuotes, quoteData.uuid, {client_name: quoteData.client_name})
      .then(setLoading(false))
    }, 500);
  }
  
  const submitFiles = (files) => {
    setFileLoading(true);
    updateFiles(setQuoteFiles, quoteData.uuid, files,setFileLoading)
    .catch(() => setFileLoading(false))
  }
  const setQuoteFiles = (files) => {
    let newArray = quoteData.quote_files.concat(files)
      setQuoteData({...quoteData, quote_files: newArray})
      setFileLoading(false)
  }

  const deleteFile = (id) => {
    appActions.ShowLoader(); 
    deleteQuoteFile(removeQuoteFile,id)
    .catch(() => appActions.HideLoader())
  }

  const removeQuoteFile = (id) => {
    let newArray = quoteData.quote_files.filter(x => x.id !== id)
    setQuoteData({...quoteData, quote_files: newArray})
    setTimeout(() => {
      appActions.HideLoader()
    },500)
  }

  //NUEVO CODIGO
  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function getAnswerValue(q) {
  if (q.data_point.name === 'Sexo del cliente') {
      return q.answer_value === "0" ? "Sin especificar" : q.answer_value;
  }

  if (isJsonString(q.answer_value)) {
      let parsedData = JSON.parse(q.answer_value);
  
    /*  console.log(parsedData);
      let formattedData = parsedData.value.map(item => {
          return {
              kinship: item.kinship,
              name: item.name,
              secondName: item.secondName,
              secondSurname: item.secondSurname,
              surname: item.surname,
              birthdate:   item.birthdate,
              phoneNumber: item.phoneNumber,
              gender: item.gender === 'M' ? 'Mujer' : 'Hombre',
              email: item.email ,
          };
      });
      console.log(formattedData);*/
      console.log(parsedData);
      return parsedData;
      //return q.answer_value;
  }
  return q.answer_value;
}

  const getGender = (gender) => {
      const kinshipMap = {
          M: 'Mujer',
          H: 'Hombre'
      };
      return kinshipMap[gender] || '';
  };


  const getInsuredTypeName = (situation) => {
    const kinshipMap = {
        T: 'Titular',
        C: 'Conyuge',
        H: 'Hijo'
    };
    return kinshipMap[situation].toUpperCase() || '';
  };

  return (
    <Paper className="pt-5 pb-12 px-8" style={{ height: "100%" }}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5 className="mb-2">
            Agente:{" "}
            {quoteData.agent &&
              quoteData.agent !== null &&
              quoteData.agent.first_name}{" "}
            {quoteData.agent &&
              quoteData.agent !== null &&
              quoteData.agent.last_name}
          </h5>
          <h6>Fecha y hora: {quoteData.created_at_str}</h6>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-secondary"
            onClick={() => history.push("/quotes/general")}
          >
            <ArrowBack /> Regresar
          </button>
          <button
            disabled={!quoteData.client_first_name}
            className="btn btn-info ml-2 d-flex"
            onClick={() => update()}
            style={{ alignItems: "center" }}
          >
            <Save className="mr-2" /> Guardar
            {loading && (
              <CircularProgress
                color="inherit"
                size={18}
                style={{ marginLeft: 10 }}
              />
            )}
          </button>
        </div>
      </div>
      <Divider className="my-4" />
      <Grid container spacing={2}>
        <Grid container item spacing={2} sm={6} alignItems="center">
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <img
              width="90"
              height="90"
              src={
                quoteData.insurance_type !== null &&
                quoteData.insurance_type.icon_1
              }
              alt={
                quoteData.insurance_type !== null &&
                quoteData.insurance_type.name
              }
            />
          </Grid>
          <Grid item xs={12} sm={8} style={{ textAlign: "center" }}>
            <h6>
              {quoteData.insurance_type !== null &&
                quoteData.insurance_type.name}
            </h6>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} alignItems="center">
          <TextField
            fullWidth
            label="Nombre de pila"
            variant="outlined"
            name="client_first_name"
            value={quoteData.client_first_name}
            onChange={handleChange}
          />
        </Grid>
        <Divider className="my-4" style={{ width: "100%" }} />
        <Grid item xs={12} className="px-5">
          <h6 style={{ padding: isMobile ? "0 10px" : "0 18%" }}>Respuestas</h6>
        </Grid>
        <Grid item xs={12} style={{ padding: isMobile ? "0 10px" : "0 18%" }}>
          <Table style={{ border: "solid 2px #e0e0e0" }}>
            <TableHead>
              <TableRow>
                <TableCell>Campo</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quoteData.quote_answers.map((q) => (
                <TableRow key={q.id}>
                  <TableCell>{q.data_point.name}</TableCell>
                  <TableCell>
                  { typeof getAnswerValue(q) === 'object' ? (
                    <div>
                      <p><strong>DATOS DEl CONTRATANTE</strong></p>
                      <br />
                      <p><strong>Nombre Completo:</strong> {getAnswerValue(q).client.personalData.name} {getAnswerValue(q).client.personalData.secondName} {getAnswerValue(q).client.personalData.surname} {getAnswerValue(q).client.personalData.secondSurname}</p>
                      <p><strong>Correo:</strong> {getAnswerValue(q).client.personalData.email} </p>
                      <p><strong>Fecha nacimiento:</strong> {getAnswerValue(q).client.personalData.birthdate} </p>
                      <p><strong>Número de teléfono:</strong> {getAnswerValue(q).client.personalData.phoneNumber} </p>
                      <p><strong>Sexo:</strong> {getGender(getAnswerValue(q).client.personalData.gender)} </p>
                      <br />
                      <br />
                      <p><strong>ASEGURADOS</strong></p>
                      <br />
                      {getAnswerValue(q).situations && Array.isArray(getAnswerValue(q).situations) ? getAnswerValue(q).situations.map((situation, index) => (
                        <div key={index}>
                          <p><strong>{getInsuredTypeName(situation.kinship)}</strong></p>
                          <p><strong>Nombre Completo: </strong>{situation.name} {situation.secondName} {situation.surname} {situation.secondSurname}</p>
                          {situation.birthdate && <p><strong>Fecha de Nacimiento:</strong> {situation.birthdate}</p>}
                          {situation.phoneNumber && <p><strong>Número de Teléfono:</strong> {situation.phoneNumber}</p>}
                          {situation.gender && <p><strong>Sexo:</strong> {getGender(situation.gender)}</p>}
                          <br />
                        </div>
                      )) : null}
                      <br />
                    </div>
          ) : <p>{getAnswerValue(q)}</p>}
                  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Divider className="my-4" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h6>Archivos</h6>
        </Grid>
        <Grid item xs={12}>
          <div className="pb-4 d-flex justify-content-end align-items-end">
            <input
              type={"file"}
              ref={file}
              multiple
              style={{ display: "none" }}
              onChange={(e) => submitFiles(e.target.files)}
            />
            <button
              disabled={false}
              className="btn btn-info ml-2 d-flex"
              onClick={() => file.current.click()}
            >
              Agregar archivos
              {file_loading && (
                <CircularProgress
                  color="inherit"
                  size={18}
                  style={{ marginLeft: 10 }}
                />
              )}
            </button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table style={{ border: "solid 2px #e0e0e0" }}>
            <TableHead>
              <TableRow>
                <TableCell>Archivo</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quoteData.quote_files.map((file) => {
                return (
                  <TableRow key={`file_${file.id}`}>
                    <TableCell>{file.file.split("/").pop()}</TableCell>
                    <TableCell>
                      <img
                        src={file.file_type.icon}
                        style={{ width: 50, height: 50 }}
                        alt={file.file_type.name}
                      />
                    </TableCell>
                    <TableCell>
                      <a
                        className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
                        target="_blank"
                        href={file.file}
                      >
                        <Visibility />
                      </a>
                      <a
                        className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger ml-3"
                        onClick={() =>
                          setAlert({
                            title: "Eliminar archivo",
                            message:
                              "¿Estás seguro que deseas eliminar el archivo?",
                            btn_msg: "Eliminar",
                            action: () => deleteFile(file.id),
                          })
                        }
                      >
                        <Delete />
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = store => ({
  quote: store.quote.quote,
})

const mapDispatchToProps = (dispatch) => ({
  quoteActions: bindActionCreators(quotesDUCK.actions, dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleQuote)
