import React , { useState }from 'react'
import { useTheme, useMediaQuery, Paper, Grid, Divider, TextField, FormControl, InputLabel, Select, MenuItem, Button, Chip, makeStyles, CircularProgress } from "@material-ui/core";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { DropNCrop } from '../../../utils/DropNCrop';
import LifeStageInsurances from './LifeStageInsurances';
import { ArrowBack, Save } from '@material-ui/icons'

import * as appDuck from '../../../redux/duck/app.duck';
import * as lifeStageDuck from '../../../redux/duck/lifeStage.duck';
import { updateLifeStage, createLifeStage } from '../../crud/api/life_stages.crud';

const useStyles = makeStyles((theme) => (
  {
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2
    }
  }
))


const LifeStage = ({ life_stage,related_insurance_types,lifeStageActions }) => {
  const history = useHistory()
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));
  const classes = useStyles();
  const [loading,setLoading] = useState(false);

  const addRelatedIns = (setterFunction, value) => {
    let newArray = life_stage.insurance_types
    value.forEach(element => {
      newArray.push(element)
    });
    setterFunction("insurance_types",newArray)
    setterFunction("new_ins", [])
  }

  const deleteRelatedIns = (setterFunction, i,deleted) => {
    const rel = life_stage.insurance_types
    deleted.push(rel[i])
    rel.splice(i, 1)
    setterFunction("related_insurances", rel)
    setterFunction("deleted",deleted)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...life_stage,
        new_ins: [],
        deleted: []
      }}
      validate={values => {
        const errors = {}

        if (!values.name) errors.name = "Campo requerido"

        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);
        if(values.id !== undefined){
          updateLifeStage(lifeStageActions.updateLifeStage,values)
          .then(() => setLoading(false))
          .catch(() => setLoading(false))
          setSubmitting(false);
        } else {
          createLifeStage(lifeStageActions.createLifeStage,values)
          .then(() => setLoading(false))
          .catch(() => setLoading(false))
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
        return (
          <>
            <div className="insurance-bg" style={{ backgroundColor: values.color }} />
            <Paper className="pt-5 pb-12 px-8" style={{ height: "100%" }}>
              <div className="d-flex justify-content-between align-items-center">
                  <h5>{ values.id === undefined ? "Nueva Etapa de vida" : "Editar Etapa de vida"}</h5>
                  <div className="d-flex">
                      <button className="btn btn-secondary" onClick={() => history.push("/life_stages/general")}><ArrowBack /> Regresar</button>
                      <button
                          disabled={(values.stage === "" ||  values.name === "" ) || isSubmitting}
                          className="btn btn-info ml-2 d-flex"
                          type="submit"
                          onClick={() => handleSubmit()}
                          style={{ alignItems: "center" }}
                      >
                          <Save color="inherit" size={18} style={{marginRight:10}}/>
                          Guardar                                
                          {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                      </button>
                  </div>
              </div>
              <Divider className="my-4" />
              <Grid container spacing={1} direction={isMobile ? "column-reverse" : "row"}>
                <Grid container spacing={1} item xs={12} sm={8} justify="center">
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Nombre de la etapa de vida"
                      name="stage"
                      value={values.stage}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Objetivo"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Descripción"
                      name="description"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={4} justify="flex-start" direction="row" alignItems="flex-start">
                  <div>
                    <DropNCrop setterFunction={setFieldValue} field={"image"} file={values.image} style={{ width: "150px", height: '150px' }} />
                  </div>
                  <div style={{marginLeft:"10px", width:"calc(100% - 170px)"}}>                  
                    <TextField
                      fullWidth
                      type="color"
                      variant="outlined"
                      label="Color"
                      name="color"
                      value={values.color}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Grid>                
              </Grid>
              <Divider style={{ margin: "20px 0" }} />
              <Grid container spacing={1}>
                {values.insurance_types.length > 0 && values.insurance_types.map((rel, i) => (
                  <LifeStageInsurances key={i} related_insurance={rel} deleteRelatedIns={deleteRelatedIns} setterFunction={setFieldValue} i={i} deleted={values.deleted}/>
                ))}
                <Grid container item spacing={1} xs={12} sm={10}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Tipo de seguro</InputLabel>
                      <Select
                        multiple
                        name="new_ins"
                        value={values.new_ins}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        renderValue={(selected) =>(
                         <div className={classes.chips}>
                           {
                             selected.map((value) => {
                               const findedValue =  related_insurance_types.data.find((insurance) => insurance === value);
                               return (
                                   <Chip color={"primary"} key={findedValue.id} label={findedValue.name} className={classes.chip} />
                               )
                             })
                           }
                         </div>
                        )}
                      >
                        {related_insurance_types.data.map(ins => (
                          <MenuItem key={ins.id} value={ins}>{ins.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={2}>
                  <Grid item xs={12}>
                    <Button fullWidth onClick={() => addRelatedIns(setFieldValue, values.new_ins)}>Agregar</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
        )
      }}
    </Formik>
)}

const mapStateToProps = store => {
  return ({
    life_stage: store.life_stage.life_stage,
    related_insurance_types: store.life_stage.related_insurance_types
  })
}

const mapDispatchToProps = (dispatch) => ({
  lifeStageActions: bindActionCreators(lifeStageDuck.actions,dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(LifeStage)
