import React, { Component } from 'react'
import { connect } from "react-redux";
import { Redirect, Switch } from 'react-router-dom';
import { bindActionCreators } from "redux";

import * as appDuck from '../../../redux/duck/app.duck';
import * as usersDuck from '../../../redux/duck/users.duck';
import { ContentRoute } from '../../../_metronic/layout';
import { getUsers } from "../../crud/api/users.crud"

import User from './User'
import SingleUser from './SingleUser'

class UserWrapper extends Component {

  constructor(props){
    super(props);
    props.appActions.ShowLoader();
  }

  componentDidMount() {
    const { userActions, appActions } = this.props

    getUsers(userActions.setUsers)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
  }

  render() {
    return (
      <Switch>
        {
          <Redirect exact from="/users" to="/users/general"/>
        }
        <ContentRoute path="/users/general" component={User}/>
        <ContentRoute path="/users/:id" component={SingleUser}/>
        <ContentRoute path="/users/new" component={SingleUser}/>
      </Switch>
    )
  }
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  userActions: bindActionCreators(usersDuck.actions, dispatch)
});

export default connect(null, dispatchToProps)(UserWrapper)