const { createMuiTheme } = require("@material-ui/core");

export const tableMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        padding: "4px 10px",
        fontSize: "1rem",
      }
    },
    MUIDataTableToolbar: {
      root: {
        background: "#4b4e5c",
        minHeight: "unset",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
        color: 'white'
      },
      icon: {
        color: 'white',

        '&:hover': {
          color: "#8850fc"
        }
      },
      titleRoot: {
        display: 'none'
      },
      iconActive: {
        color: "#8850fc"
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 90
      }
    },
  }
})

export const tableMuiThemeNoToolbar = () => createMuiTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        padding: "4px 10px",
        fontSize: "1rem",
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: 90
      }
    },
  }
})


export const users_data = [
  { id: 1, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: false },
  { id: 2, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Jane", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: true },
  { id: 3, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: false },
  { id: 4, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Mary", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: false },
  { id: 5, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 6, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 7, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Andrea", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: true },
  { id: 9, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: false },
  { id: 8, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: false },
  { id: 10, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Sandra", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 11, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Janeth", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 12, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: false },
  { id: 13, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: false },
  { id: 14, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: false },
  { id: 15, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Janeth", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 16, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: true },
  { id: 17, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: false },
  { id: 18, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: false, status: true },
  { id: 19, image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg", first_name: "Adrien", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: true },
  { id: 20, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU", first_name: "Sandra", last_name: "jhons", email: "adr@ca-seguros.com", agent: true, status: false },
]

export const blog_data = [
  { id: 1, titulo: 'Impacto COVID', descripcion: 'Impacto economico COVID mexico', nota: "Covid-19 podría costar 300 mdd a las aseguradoras de México", fecha: "2020-09-04", status: false },
  { id: 2, titulo: 'La salud ante todo', descripcion: 'Mexicanos, cambian sus preferencias ante covid', nota: "Por Covid 19, mexicanos de toda la república dejan de pagar seguro de autos y optan por pagar gastos médicos debido a los gadecimiento generados", fecha: "2020-09-04", status: true },
  { id: 3, titulo: 'Daños colaterales COVID mexico', descripcion: 'Sacrifican su vida por su compromiso', nota: "México, primer lugar mundial en personal de salud fallecido por COVID-19: Amnistía Internacional", fecha: "2020-09-04", status: false },
  { id: 4, titulo: 'Los jovenes primero', descripcion: 'Apoyo de salud para jovenes estudiantes', nota: "Seguros médicos para jóvenes estudiantes en la Educación Superior", fecha: "2020-09-04", status: true },
]

const testimonies = [
  {
    id: 1,
    testimony_name: "¿Sabias que el costo de una hospitalizacion puede terminar con tus ahorros?",
    testimony: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    image: "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"
  },
  {
    id: 2,
    testimony_name: "¿Ahorras par tu retiro?",
    testimony: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSjtU6G7eH3COAumnAB34AoGQG2RIOx4O7NnQ&usqp=CAU"
  },
]
const fun_facts = [
  {
    id: 1,
    fun_fact: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    description: "",
    image: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg"
  },
  {
    id: 2,
    fun_fact: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    description: "",
    image: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg"
  },
]

export const insurance_type_data = [
  {
    id: 1,
    name: "Gastos médicos mayores",
    link: "",
    desc: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    icon: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg",
    color: "#f66f52",
    q_rating: 12,
    rating: 4.5,
    testimonies: testimonies,
    fun_facts: fun_facts,
    sub_types: [],
  },
  {
    id: 2,
    name: "Gastos funerarios",
    link: "",
    desc: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    icon: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg",
    color: "#406a95",
    q_rating: 12,
    rating: 5,
    testimonies: testimonies,
    fun_facts: fun_facts,
    sub_types: [],
  },
  {
    id: 3,
    name: "Seguro de auto",
    image: "",
    desc: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    icon: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg",
    color: "#406a95",
    q_rating: 12,
    rating: 4.5,
    testimonies: testimonies,
    fun_facts: fun_facts,
    sub_types: [],
  },
  {
    id: 4,
    name: "Seguro para el ahorro",
    image: "",
    desc: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    icon: "https://media.self.com/photos/5a53d8e5dc64a87a5a0e9bba/4:3/w_2560%2Cc_limit/Heart-Palpitations.jpg",
    color: "#406a95",
    q_rating: 12,
    rating: 4,
    testimonies: testimonies,
    fun_facts: fun_facts,
    sub_types: [],
  },
]

export const life_stages_data = [
  {
    id: 1,
    name: "15 años",
    subtitle: "Lorem ipsum",
    image: "",
    color: "#406a95",
    description: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    related_insurances: []
  },
  {
    id: 2,
    name: "18 años",
    subtitle: "Lorem ipsum",
    image: "",
    color: "#406a95",
    description: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    related_insurances: []
  },
  {
    id: 3,
    name: "22 años",
    subtitle: "Lorem ipsum",
    image: "",
    color: "#406a95",
    description: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    related_insurances: []
  },
  {
    id: 4,
    name: "25 años",
    subtitle: "Lorem ipsum",
    image: "",
    color: "#406a95",
    description: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur",
    related_insurances: []
  },
]

export const defaultLifeStage = {
  name: "",
  subtitle: "",
  image: "",
  color: "#000",
  description: "",
  related_insurances: [],
}