import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultLifeStage = {
    name: "", 
    description: "", 
    stage: "", 
    image: "", 
    image_thumb: "",
    color: "#4b4e5c",
    insurance_types: []
}

export const actionType = {
    GetLifeStages: '[LIFESTAGE] GET_LIFE_STAGES',
    GetLifeStage: '[LIFESTAGE] GET_LIFE_STAGE',
    CreateLifeStage: '[LIFESTAGE] CREATE_LIFE_STAGE',
    UpdateLifeStage: '[LIFESTAGE] UPDATE_LIFE_STAGE',   
    GetRelatesInsuranceTypes: '[LIFESTAGE] GET_RELATED_INSURANCE_TYPES',
    DeleteLifeStage: '[LIFESTAGE] DELETE_LIFE_STAGE'
}

const initialState = {
    life_stages: {data: [], count: 0, page: 0, rowsPerPage: 15, isFetched: false},
    related_insurance_types: {data: [], isFetched: false},
    life_stage: defaultLifeStage
}

export const reducer = persistReducer(
    { storage, key: 'life_stage', whitelist: [] },
    (state = initialState, action) => {
        switch (action.type) {
            case actionType.GetLifeStages: {
                return { ...state,life_stages: {data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true }}            
            }
            case actionType.CreateLifeStage: {
                let newData = [...state.life_stages.data]
                newData.push(action.payload)
                return {...state, life_stages: {...state.life_stages, data: newData , isFetched: true}, life_stage: action.payload, tabValue: action.payload.id}
            }
            case actionType.GetLifeStage: {
                return {...state, life_stage: action.payload}
            }
            case actionType.UpdateLifeStage: {
                return {...state, life_stage: action.payload}
            }
            case actionType.GetRelatesInsuranceTypes: {
                return {...state, related_insurance_types: {data: action.payload.items, isFetched: true}}
            }
            case actionType.DeleteLifeStage: {
                let newData = state.life_stages.data.filter((x) => x.id !== action.payload.id )
                return {...state, life_stages: {...state.life_stages, data: newData}}
              }
            default: return state
        }
    }
)

export const actions = {
    setLifeStages: data => ({type: actionType.GetLifeStages, payload: data}),
    setLifeStage: data => ({type: actionType.GetLifeStage, payload: data}),
    createLifeStage: data => ({type: actionType.CreateLifeStage, payload: data}),
    updateLifeStage: data => ({type: actionType.UpdateLifeStage, payload: data}),
    setRelatedInsuranceType: data => ({type: actionType.GetRelatesInsuranceTypes, payload: data}),
    deleteLifeStage: data => ({type: actionType.DeleteLifeStage, payload: data})
}