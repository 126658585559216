import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultInsuranceType = {
  testimonials: [],
  fun_facts: [],
  subtypes: [],
  name: "", 
  description: "", 
  slug: "", 
  video_url: "", 
  rating: 1, 
  review_count: 0, 
  icon_1: "",  
  icon_2: "",  
  icon_3: "",  
  has_children: false,
  visible: false,
  display_text: "",
  page_title:"",
  page_subtitle: "",
  page_sections: [],
  page_downloadables: []
}

export const actionType = {
  GetInsuranceTypes: '[INSURANCE_TYPES] GET_INSURANCE_TYPES',
  GetInsuranceType: '[INSURANCE_TYPES] GET_INSURANCE_TYPE',
  CreateInsuranceType: '[INSURANCE_TYPES] CREATE_INSURANCE_TYPES',
  UpdateInsuranceType: '[INSURANCE_TYPES] UPDATE_INSURANCE_TYPES',
  DeleteInsuranceType: '[INSURANCE_TYPES] DELETE_INSURANCE_TYPES'
}

const initialState = {
  insurance_types: { data: {items: [], per_page:15, page: 1, total:0}, isFetched: false },
  insurance_type: { data: defaultInsuranceType, isFetched: false},
}

export const reducer = persistReducer(
  { storage, key: 'ins_types', withlist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetInsuranceTypes: {
        return { ...state, insurance_types: { data: action.payload, isFetched: true } }
      }
      case actionType.CreateInsuranceType: {
        let newData = [...state.insurance_types.data.items]
                newData.push(action.payload)
                return {...state, insurance_types: {data: {...state.insurance_types.data, items: newData}, isFetched: true}, insurance_type: { data: action.payload, isFetched: true } }
      }
      case actionType.UpdateInsuranceType: {
        return {...state, insurance_type: { data: action.payload, isFetched: true}}
      }
      case actionType.GetInsuranceType: {
        return { ...state, insurance_type:  { data: action.payload, isFetched: action.fetched} }
      }
      case actionType.DeleteInsuranceType: {
        let newData = state.insurance_types.data.items.filter((x) => x.id !== action.payload.id )
        return {...state, insurance_types: {data: {...state.insurance_types.data, items: newData}, isFetched: true}}
      }
      default: return state
    }
  }
)

export const actions = {
  setInsuranceTypes: data => ({ type: actionType.GetInsuranceTypes, payload: data }),
  setInsuranceType: (data, fetched = true) => ({ type: actionType.GetInsuranceType, payload: data, fetched: fetched }),
  createInsuranceType: data => ({ type: actionType.CreateInsuranceType, payload: data }),
  updateInsuranceType: data => ({ type: actionType.UpdateInsuranceType, payload: data }),
  deleteInsuranceType: data => ({ type: actionType.DeleteInsuranceType, payload: data })
}