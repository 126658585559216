import React, { Component } from 'react'
import InsuranceType from './InsuranceType'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appDuck from '../../../redux/duck/app.duck';
import * as insuranceTypeDuck from '../../../redux/duck/InsuranceType.duck';
import {getInsuranceTypes } from '../../crud/api/insurance_types.crud';
import { ContentRoute } from '../../../_metronic/layout';
import SingleInsuranceType from './SingleInsuranceType';
import { Redirect, Switch } from 'react-router-dom';
import InsuranceQuote from '../InsuranceQuote/InsuranceQuote';

class InsuranceTypeWrapper extends Component {
  constructor(props){
    super(props);
    props.appActions.ShowLoader();
  }

  componentDidMount() {
    const {appActions,insuranceTypeActions} = this.props
    getInsuranceTypes(insuranceTypeActions.setInsuranceTypes)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader()) 
  }
  
  render() {
    return (
      <Switch>
        {
          /* Redirect from root URL to /users. */
          <Redirect exact from="/insurance_types" to="/insurance_types/general" />
        }
        <ContentRoute path="/insurance_types/general" component={InsuranceType}/>
        <ContentRoute path="/insurance_types/new" component={SingleInsuranceType}/>
        <ContentRoute path="/insurance_types/:slug/edit" component={SingleInsuranceType}/>
        <ContentRoute path="/insurance_types/:slug/insurance_quote" component={InsuranceQuote}/>
      </Switch>
    )
  }
}

const mapStateToProps = store => {
  return({
    insurance_types: store.ins_types
  })
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  insuranceTypeActions: bindActionCreators(insuranceTypeDuck.actions,dispatch)
});

export default connect(mapStateToProps, dispatchToProps)(InsuranceTypeWrapper)