import React, { useState } from 'react'
import { connect } from "react-redux";
import { Redirect, useHistory } from 'react-router-dom';
import { Paper, Grid, useTheme, useMediaQuery, Divider, TextField, Button, CircularProgress, FormControlLabel, Switch, IconButton, FormControl, FormHelperText, RadioGroup, Radio, Typography } from '@material-ui/core'
import { ArrowBack, Delete, Publish } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';

import FunFact from './FunFact/FunFact';
import notice from '../../../utils/notice';
import Testimony from './Testimony/Testimony';
import { DropNCrop } from '../../../utils/DropNCrop';
import HTMLEditor from '../../../utils/HTMLEditor';
import PageDownloadable from './PageDownloadable/PageDownloadable';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {createInsuranceType, updateInsuranceType } from '../../crud/api/insurance_types.crud';
import { getQuoteSlides, getCaptureMechanisms } from '../../crud/api/insurance_quote.crud';
import * as insuranceTypeDuck from '../../../redux/duck/InsuranceType.duck';
import * as insQuoteDUCK from "../../../redux/duck/InsuranceQuote.duck";
import * as appDuck from '../../../redux/duck/app.duck';
import { setAlert } from '../../../redux/duck/alert.duck';


const SingleInsuranceType = ({ insurance_type, insuranceTypeActions, appActions, quoteActions, setAlert }) => {
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));
  const [loading,setLoading] = useState(false);
  const history = useHistory()
  const [thumbnail_url,setThumbnailUrl] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6g3A-HaH_U5qZAhbsjuVvttJfhLx52ZsCOQ&usqp=CAU");

  const addTestominy = (setterFunction, value) => {
    const oldT = insurance_type.data.testimonials
    oldT.push(value)
    setterFunction("testimonials", oldT)
    setTimeout(() => {
      setterFunction("new_testimony",{ person_name: "", person_avatar: "", content: ""})
    }, 500);
  }

  const deleteTestimony = (i, setterFunction, deleted) => {
      const oldT = insurance_type.data.testimonials
      if(oldT[i].id !== undefined){
        deleted.push(oldT[i].id)
        setterFunction("deleted_testimonials",deleted)
      }
      oldT.splice(i, 1)
      setterFunction("testimonials", oldT)
  }

  const addPageDownloadable = (setterFunction,value) => {
    const oldPD = insurance_type.data.page_downloadables
    oldPD.push(value)
    setterFunction("page_downloadables",oldPD)
    setTimeout(() => {
      setterFunction("new_page_downloadable",{name: "", file: null})
    },500);
  }

  const deletePageDownloadable = (i, setterFunction, deleted) => {
    const oldPD = insurance_type.data.page_downloadables
    if(oldPD[i].id !== undefined){
      deleted.push(oldPD[i].id)
      setterFunction("deleted_downloadables",deleted)
    }
    oldPD.splice(i,1)
    setterFunction("page_downloadables",oldPD)
  }

  const addFunFact = (setterFunction, value) => {
    const mock = { content: value }
    const oldT = insurance_type.data.fun_facts
    oldT.push(mock)
    setterFunction("fun_facts", oldT)
    setTimeout(() => {
      setterFunction("new_fun_fact", "")
    }, 500);
  }

  const deleteFunFact = (i, setterFunction, deleted) => {
    const oldT = insurance_type.data.fun_facts
    if(oldT[i].id !== undefined){
      deleted.push(oldT[i].id)
      setterFunction("deleted_fun_facts",deleted)
    }
    oldT.splice(i, 1)
    setterFunction("fun_facts", oldT)
  }

  // const addSubType = (setterFunction) => {
  //   const oldST = insurance_type.data.subtypes
  //   oldST.push(defaultInsuranceType)
  //   setterFunction("subtypes", oldST)
  // }

  // const deleteSubtype = (setterFunction, i) => {
  //   const oldT = insurance_type.data.sub_types
  //   oldT.splice(i, 1)
  //   setterFunction("sub_types", oldT)
  // }

  const getSlides = () => {
    getCaptureMechanisms(quoteActions.setCaptureMechanisms)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
    
    getQuoteSlides(quoteActions.setInsQuote,insurance_type.data)
    .then(() => { history.push(`/insurance_types/${insurance_type.data.slug}/insurance_quote`); appActions.HideLoader()})
    .catch(() => appActions.HideLoader()) 
  }

  const defaultPageSection = {title: "", subtitle: "", body_html: ""}
  const defaultCard = {title: "", description: "", image: ""}
  const addNewSection = (setterFunction,sections) => {
    let newSections = sections;
    newSections.push(defaultPageSection)
    setterFunction("page_sections",newSections);
  }
  const deleteSection = (setterFunction,index,sections) => {
    const oldSection = sections
    oldSection.splice(index,1)
    setterFunction("page_sections",oldSection)
  }
  const changeDataType = (setterFunction,value,i) => {
    if(value === "text"){
      setterFunction(`page_sections[${i}]['cards']`,null)
      setterFunction(`page_sections[${i}]['body_html']`,"")
    }else{
      setterFunction(`page_sections[${i}]['body_html']`,null)
      setterFunction(`page_sections[${i}]['cards']`,[defaultCard])      
    }
  }
  const addNewCard = (setterFunction,cards,i) => {
    let newCards = cards;
    newCards.push(defaultCard)
    setterFunction(`page_sections[${i}]['cards']`,newCards);
  }
  const deleteCard = (setterFunction,cards,i,index) => {
    const oldCards = cards
    oldCards.splice(index,1)
    setterFunction(`page_sections[${i}]['cards']`,oldCards)
  }

  const reorderSections = (list, startIndex, endIndex, setterFunction) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setterFunction("page_sections",result)
  }
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#EEE5FF" : "transparent",
    borderRadius: 5,
    padding: 8,
    width: "100%"
  });
  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });
  const onDragEnd = (result, sections, setterFunction) => {
    if (!result.destination) {
      return;
    }else {
      reorderSections(sections,result.source.index,result.destination.index,setterFunction)
    }
  }

  const getThumbnail = (url) => {
    if(url.includes("youtube")){
      let video_url = new URL(url)
      let id = ""
      if(url.includes("watch"))
        id = video_url.searchParams.get('v')
      else
        id = video_url.pathname.split("/").pop()

      setThumbnailUrl(`https://img.youtube.com/vi/${id}/0.jpg`)
    }
  }
  
  return (
      <Formik
      enableReinitialize
      initialValues={{
        ...insurance_type.data,
        new_testimony: { person_name: "", person_avatar: "", content: ""},
        new_page_downloadable: { name: "", file: null},
        new_fun_fact: "",
        deleted_testimonials: [],
        deleted_fun_facts: [],
        deleted_downloadables: [],
        page_sections: JSON.stringify(insurance_type.data.page_sections) === "{}" ? [] : insurance_type.data.page_sections
      }}
      validate={values => {
        const errors = {}

        if (!values.name) errors.name = "Campo requerido"

        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {   
        values.page_sections.forEach(element => {
          if(element.body_html === null)
            delete element["body_html"]
          if(element.cards === null)
            delete element["cards"]
        });            
        if(values.icon_1 === "" || values.icon_1 === null || values.slug === "" || values.name === ""){          
          notice("El nombre, slug e icono son campos obligatorios ")
          setSubmitting(false);
        }else{
          setSubmitting(true);
          setLoading(true);
          if(values.id !== undefined){
            updateInsuranceType(insuranceTypeActions.updateInsuranceType,values)
            .then(() => {setLoading(false); setSubmitting(false);})
            .catch(() => {setLoading(false); setSubmitting(false);})
          }else{
            createInsuranceType(insuranceTypeActions.createInsuranceType,values)
            .then(() => {setLoading(false); setSubmitting(false);})
            .catch(() => {setLoading(false); setSubmitting(false);})
          }
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
        return (
          <>
            {
              !insurance_type.isFetched ? 
              <Redirect to="/insurance_types"/> : ""
            }
            <div className="pb-4 d-flex justify-content-end align-items-end">
              <Button size="large" variant="contained" onClick={() => { 
                insuranceTypeActions.setInsuranceType(insuranceTypeDuck.defaultInsuranceType, false)}}>
                <ArrowBack /> Regresar
              </Button>
              <Button size="large" variant="contained" onClick={() => getSlides()} className="ml-2">
                Ir a cotizador
              </Button>
              <button disabled={isSubmitting} className="btn btn-info ml-2 d-flex" type="submit" onClick={() => handleSubmit()}>
                Guardar
                {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
              </button>
            </div>
            <Paper style={{ padding: 20, marginBottom: 5 }} elevation={3}>
              <Grid container spacing={1} direction={isMobile ? "column-reverse" : "row"}>
                <Grid container spacing={1} item xs={12} sm={10} justify="center">
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.visible && errors.visible)}>
                      <FormControlLabel
                        control={<Switch checked={values.visible}  onBlur={handleBlur} onChange={handleChange} name="visible"/>}
                        label="Visible al público"/>
                      <FormHelperText>{touched.visible && errors.visible}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Nombre del tipo de seguro"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Slug"
                      name="slug"
                      value={values.slug}
                      onBlur={handleBlur}
                      onChange={handleChange}                     
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Texto a mostrar"
                      name="display_text"
                      value={values.display_text}
                      onBlur={handleBlur}
                      onChange={handleChange}                     
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <HTMLEditor content={values.description} setterFunction={setFieldValue} field={"description"}/>  
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Título"
                      name="page_title"
                      value={values.page_title}
                      onBlur={handleBlur}
                      onChange={handleChange}                     
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label="Subtítulo"
                      name="page_subtitle"
                      value={values.page_subtitle}
                      onBlur={handleBlur}
                      onChange={handleChange}                     
                    />
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:8}}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Link de youtube"
                      name="video_url"
                      value={values.video_url}
                      onBlur={handleBlur}
                      onChange={e => {setFieldValue("video_url",e.target.value); getThumbnail(e.target.value)}}
                    />
                  </Grid>
                  {values.video_url && (values.video_url.includes("http://", 0) || values.video_url.includes("https://", 0)) &&
                    <Grid item xs={12} style={{marginBottom:8}}>                  
                      <Paper style={{padding: 5}}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">              
                          <img src={thumbnail_url} style={{width: 100, height: 100}}/>
                          <Typography variant="h6" style={{marginLeft:10}}>{new URL(values.video_url).hostname}</Typography>
                        </Grid>
                      </Paper>
                    </Grid>}
                  <Grid item xs={12}>
                  {/*<FormControlLabel
                    control={<Switch checked={values.has_children} onChange={handleChange} name="has_children" />}
                  label="Tiene hijos"/> */}                   
                  </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={2} justify={isMobile ? "space-evenly" : "center"}>
                  <Grid container item justify="center" xs={12}>
                    <DropNCrop setterFunction={setFieldValue} field={"icon_1"} file={values.icon_1} style={{ width: "150px", height: '100px' }}/>
                  </Grid>
                  <Grid container item justify="center" xs={12}>
                    <DropNCrop setterFunction={setFieldValue} field={"icon_2"} file={values.icon_2} style={{ width: "150px", height: '100px' }}/>
                  </Grid>
                  <Grid container item justify="center" xs={12}>
                    <DropNCrop setterFunction={setFieldValue} field={"icon_3"} file={values.icon_3} style={{ width: "150px", height: '100px' }}/>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>         
            <Divider style={{ margin: "20px 0" }} />
            <Paper style={{ padding: 20, marginBottom: 5 }} elevation={3}>              
              <h5 style={{marginBottom: 10}}>Testimonios</h5>
              {values.testimonials.length > 0 && values.testimonials.map((testimony, i) => (
                <Testimony key={i} testimony={testimony} i={i} setterFunction={setFieldValue} deleteTestimony={deleteTestimony} deleted={values.deleted_testimonials}/>
              ))}
              <Grid container spacing={1}>
                <Grid container spacing={1} item xs={12} sm={10}>
                  <Grid item xs={9} sm={10}>
                  <TextField
                      fullWidth
                      variant="outlined"
                      label="Nombre del cliente"
                      name="new_testimony['person_name']"
                      value={values.new_testimony.person_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{marginBottom:10}}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Testimonio"
                      name="new_testimony['content']"
                      value={values.new_testimony.content}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Button
                      style={{ height: "100%" }}
                      onClick={() => addTestominy(setFieldValue, values.new_testimony)}
                    >
                      Agregar Testimonio
                    </Button>
                  </Grid>
                </Grid>
              </Grid>              
            </Paper>
            <Divider style={{ margin: "20px 0" }} />           
            <Paper style={{ padding: 20, marginBottom: 10 }} elevation={3}>            
              <h5 style={{marginBottom: 10}}>Fun Facts</h5>
              <Grid container spacing={2} alignItems="center">
                {
                  isMobile ? 
                  <Grid item xs={12}>
                    <center>
                      <DropNCrop setterFunction={setFieldValue} field={"fun_facts_image"} file={values.fun_facts_image} style={{ width: "150px", height: '150px' }}/>
                    </center>
                  </Grid>
                  : <></>
                }
                <Grid item xs={12} sm={10}>
                  <TextField
                      fullWidth
                      variant="outlined"
                      label="Titulo"
                      name="fun_facts_title"
                      value={values.fun_facts_title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{marginBottom:10}}
                    />
                    {values.fun_facts.length > 0 && values.fun_facts.map((fun_fact, i) => (
                      <FunFact key={i} fun_fact={fun_fact} setterFunction={setFieldValue} i={i} deleteFunFact={deleteFunFact}  deleted={values.deleted_fun_facts}/>
                    ))}
                </Grid>
                {
                  !isMobile ? 
                  <Grid item xs={12} sm={2}>
                    <DropNCrop setterFunction={setFieldValue} field={"fun_facts_image"} file={values.fun_facts_image} style={{ width: "150px", height: '100px' }}/>
                  </Grid>
                  : <></>
                }
              </Grid>              
              <Grid container spacing={1}>
                <Grid container spacing={1} item xs={12} sm={10}>
                  <Grid item xs={9} sm={10}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Nuevo fun fact"
                      name="new_fun_fact"
                      value={values.new_fun_fact}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Button
                      style={{ height: "100%" }}
                      onClick={() => addFunFact(setFieldValue, values.new_fun_fact)}
                    >
                      Agregar fun fact
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Divider style={{ margin: "20px 0" }} /> 
            <DragDropContext onDragEnd={(result) => onDragEnd(result,values.page_sections,setFieldValue)}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {values.page_sections.map((section, i) => (
                      <Draggable key={`SECTION_${i}`} draggableId={`SECTION_${i}`} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              provided.draggableProps.style
                            )}
                          >
                          <Paper elevation={3} style={{marginBottom:10, padding:20, border: snapshot.isDragging ? "2px solid #8950FC" : "none"}}>
                            <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} style={{marginBottom:8}}>
                                    <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              label="Título"
                                                              name={`page_sections[${i}]['title']`}
                                                              value={section.title}
                                                              onBlur={handleBlur}
                                                              onChange={handleChange}
                                                            />
                                  </Grid>
                                  <Grid item xs={12}  style={{marginBottom:8}}>
                                    <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              label="Subtítulo"
                                                              name={`page_sections[${i}]['subtitle']`}
                                                              value={section.subtitle}
                                                              onBlur={handleBlur}
                                                              onChange={handleChange}
                                                            />
                                  </Grid>
                                  <Grid item xs={12}  style={{marginBottom:8}}>
                                    <FormControl>
                                      <RadioGroup value={section.body_html !== undefined && section.body_html !== null ? "text" : "cards"} onChange={(e) => changeDataType(setFieldValue,e.target.value,i)}>
                                        <FormControlLabel value="text" control={
                                          <Radio />} label="Texto" />
                                          <FormControlLabel value="cards" control={
                                            <Radio />} label="Tarjetas" />
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>                        
                                                          {
                                                            section.body_html !== undefined && section.body_html !== null ?
                                                            
                                      <Grid item xs={12}  style={{marginBottom:8}}>
                                        <h5 style={{marginBottom: 10}}>Contenido</h5>
                                        <HTMLEditor content={section.body_html} setterFunction={setFieldValue} field={`page_sections[${i}]['body_html']`}/>
                                      </Grid> :
                                                            
                                      <Grid item xs={12}  style={{marginBottom:8}}>
                                        <h5 style={{marginBottom: 15}}>Tarjetas</h5>
                                                              {
                                                                section.cards.map((card,index) => (
                                                                  
                                        <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{marginBottom:20}}>
                                          <div style={{width: isMobile ? "100%" : "calc(100% - 250px)",marginRight: isMobile ? 0 : 10}}>
                                            <TextField
                                                                        style={{marginBottom:10}}
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        label="Título"
                                                                        name={`page_sections[${i}]['cards'][${index}]['title']`}
                                                                        value={card.title}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                      />
                                            <HTMLEditor content={card.description} setterFunction={setFieldValue} field={`page_sections[${i}]['cards'][${index}]['description']`} style={{marginBottom: isMobile ? 10 : 0}}/>  
                                          </div>
                                          <DropNCrop isBase64={true} setterFunction={setFieldValue} field={`page_sections[${i}]['cards'][${index}]['image']`} file={card.image} style={{ width: "150px", height: '100px', marginRight:10, marginTop: isMobile ? 10 : 0 }} ratio="1/1"/>
                                          <IconButton onClick={() => {setAlert({
                                                                            title: "Eliminar tarjeta",
                                                                            message: "¿Estás seguro que deseas eliminar la tarjeta?",
                                                                            btn_msg: "Eliminar",
                                                                            action: () => deleteCard(setFieldValue,section.cards,i,index)
                                                                          })
                                                                      }}>
                                            <Delete />
                                          </IconButton>
                                        </Grid>
                                                                ))
                                                              }
                                                              
                                        <center style={{marginTop:15}}>
                                          <Button variant="contained" className="btn btn-info ml-2 d-flex"  onClick={() => addNewCard(setFieldValue,section.cards,i)}>Agregar tarjeta</Button>
                                        </center>
                                      </Grid>
                                                          }
                                                          
                                                        
                                    </Grid>
                                  </Grid>
                                  <Grid container item justify="center" alignItems="center" xs={2}>
                                    <IconButton onClick={() => {setAlert({
                                                              title: "Eliminar sección",
                                                              message: "¿Estás seguro que deseas eliminar la sección?",
                                                              btn_msg: "Eliminar",
                                                              action: () => deleteSection(setFieldValue,i,values.page_sections)
                                                            })
                                                        }}>
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                          </Paper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>  
            <center>
              <Button variant="contained" className="btn btn-info ml-2 d-flex" style={{marginTop:10}} onClick={() => addNewSection(setFieldValue,values.page_sections)}>Agregar sección</Button>
            </center>
            <Divider style={{ margin: "20px 0" }} />     
            <Paper style={{ padding: 20, marginBottom: 10 }} elevation={3}>
              <h5 style={{marginBottom: 10}}>Descargables</h5>
              {values.page_downloadables.length > 0 && values.page_downloadables.map((page_downloadable, i) => (
                <PageDownloadable key={i} page_downloadable={page_downloadable} i={i} setterFunction={setFieldValue} deletePageDownloadable={deletePageDownloadable} deleted={values.deleted_downloadables}/>
              ))}
              <Grid container spacing={2}>
                <Grid item xs={9} sm={10}>
                  <TextField
                      fullWidth
                      variant="outlined"
                      label="Nombre"
                      name="new_page_downloadable['name']"
                      value={values.new_page_downloadable.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{marginBottom:10}}/>
                  <div className="d-flex align-items-center">
                  <input
                    hidden
                    type="file"
                    id="contained-button-file"
                    onChange={e => setFieldValue("new_page_downloadable['file']", e.target.files[0])}/>
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="secondary" component="span" startIcon={<Publish />}>
                        Seleccionar
                      </Button>
                    </label>
                    <Typography className="ml-3">{values.new_page_downloadable.file !== null ? values.new_page_downloadable.file.name : ""}</Typography>
                  </div>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <Button
                    style={{ height: "100%" }}
                    onClick={() => addPageDownloadable(setFieldValue, values.new_page_downloadable)}>
                      Agregar Descargable
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = store => {
  return({
    insurance_type: store.ins_types.insurance_type
  })
}

const mapDispatchToProps = (dispatch) => ({
  insuranceTypeActions: bindActionCreators(insuranceTypeDuck.actions,dispatch),
  quoteActions: bindActionCreators(insQuoteDUCK.actions, dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SingleInsuranceType)