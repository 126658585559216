import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";

import { rootReducer } from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware())
);

export const persistor = persistStore(store);

export default store;

