import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'
import { CancelRounded } from '@material-ui/icons';

const cropper = React.createRef(null);

export const DropNCrop = ({ setterFunction, field, file, aspectRatio, isBase64 = false, ...props }) => {
  const [image, setImage] = useState()
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result));
      reader.readAsDataURL(acceptedFiles[0])
    }
  });

  function deleteFile() {
    setImage(null)
    setterFunction(field, null)
  }
  
  const cropImage = () => {
    if(field === "og_image"){
      var canvas = document.createElement('canvas');  
      canvas.width  = 1200;  
      canvas.height = 1200; 
      var ctx = canvas.getContext("2d");
      var image = new Image();
      image.onload = function() {
          ctx.drawImage(image, 0, 0,1200,1200);
          canvas.toBlob(function(blob){
            let newFile = new File([blob], "image", { type: "image/jpg" })	
            Object.assign(newFile, {src: canvas.toDataURL("image/jpeg")})
            setterFunction(field, newFile)
            setTimeout(() => {
              setImage(null)
            }, 500);
          },"image/jpeg", 1)
      };
      image.src = cropper.current.getCroppedCanvas().toDataURL();
    }else if(field === "twitter_image"){
      var canvas2 = document.createElement('canvas');  
      canvas2.width  = 1200;  
      canvas2.height = 675; 
      var ctx2 = canvas2.getContext("2d");
      var image2 = new Image();
      image2.onload = function() {
          ctx2.drawImage(image2, 0, 0,1200,675);
          canvas2.toBlob(function(blob){
            let newFile = new File([blob], "image", { type: "image/jpg" })	
            Object.assign(newFile, {src: canvas2.toDataURL("image/jpeg")})
            setterFunction(field, newFile)
            setTimeout(() => {
              setImage(null)
            }, 500);
          },"image/jpeg", 1)
      };
      image2.src = cropper.current.getCroppedCanvas().toDataURL();
    }else {
      if(isBase64){
        setterFunction(field,cropper.current.getCroppedCanvas().toDataURL())
        setTimeout(() => {
          setImage(null)
        }, 500);
      }else {
        fetch(cropper.current.getCroppedCanvas().toDataURL())
        .then(res => res.blob())
        .then(blob => {
          let newFile = new File([blob], "image", { type: "image/jpg" })	
          Object.assign(newFile, {src: cropper.current.getCroppedCanvas().toDataURL()})
          setterFunction(field, newFile)
          setTimeout(() => {
            setImage(null)
          }, 500);
        })
        .catch(e => console.log(e))
        }
      }
  }

  return (
    <>
      <div id="kt_dropzone_1" className={`dropzone dropzone-default needsclick ${file ? 'active' : ''}`} {...props}>
        {file && file !== null ?
          <div className="dz-preview">
            <div className="dz-image">
              <img src={file !== null ? file.src ? file.src : file : ""} alt="image_preview"/>             
            </div>
            <div className="dz-remove" onClick={(e) => { e.preventDefault(); deleteFile() }}>
              <CancelRounded fontSize="large" style={{ width: "100%", height: "100%" }} />
            </div>
          </div> :
          <div className="dz-message needsclick" {...getRootProps()}>
            <input {...getInputProps()} />
            <h3 className="dropzone-msg-title">+</h3>
          </div>}
      </div>

      <Dialog open={image !== null && image !== undefined} onClose={() => deleteFile()}>
        <DialogTitle>Crop your image</DialogTitle>
        <DialogContent>
          <Cropper
            ref={cropper}
            src={image}
            style={{ height: 400, width: '100%' }}
            // Cropper.js options
            aspectRatio={aspectRatio ? aspectRatio : 1 / 1}
            guides={false}
          />
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-primary btn-elevate kt-login__btn-primary"
            onClick={() => cropImage()}
          >
            Crop Image
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
