import React, {Component} from 'react'
import { connect } from "react-redux";
import { Redirect, Switch } from 'react-router-dom';
import { bindActionCreators } from "redux";

import { ContentRoute } from '../../../_metronic/layout';

import * as appDuck from '../../../redux/duck/app.duck';
import * as blogDuck from '../../../redux/duck/blogs.duck';
import { getBlogs } from '../../crud/api/blogs.crud';

import Blog from './Blog'
import BlogInfo from './BlogInfo'

class BlogWrapper extends Component {

  constructor(props){
    super(props);
    props.appActions.ShowLoader();
  }

  componentDidMount() {
    const { blogActions, appActions } = this.props
    getBlogs(blogActions.setBlogs)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())

  }

  render() {
    return (
      <>
        <Switch>
          {
            /* Redirect from root URL to /users. */
            <Redirect exact from="/blog" to="/blog/list" />
          }
          <ContentRoute path="/blog/list" component={Blog} />
          <ContentRoute path="/blog/new" component={BlogInfo} />
          <ContentRoute path="/blog/:slug/edit" component={BlogInfo} />
        </Switch>
      </>
    )
  }
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  blogActions: bindActionCreators(blogDuck.actions, dispatch),
});

export default connect(null, dispatchToProps)(BlogWrapper)