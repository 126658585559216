/* eslint-disable array-callback-return */
import { handleResponse, getMultipartParams, getParams } from "../helpers/validate"
import { USERS_URL } from "../helpers/routes"
import notice from "../../../utils/notice"

export const getUsers = (setterFunction, page = 1) => {
  return fetch(`${USERS_URL}?page=${page}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error al cargar los usuarios")
    console.error(e)
    throw e
  })
}

export const createUser = (setterFunction,params) => {
  var formdata = new FormData();
  Object.keys(params).map(key => {
    if(key === "profile_picture"){
        if(typeof params[key] === "object")
        formdata.append(key, params[key])
    }else{
        formdata.append(key, params[key])
    }
})
  return fetch(`${USERS_URL}/create`,getMultipartParams("POST",formdata))
  .then(res => handleResponse(res))
  .then(json => {setterFunction(json)
    notice("Usuario creado con éxito","success")
  })
  .catch(e => {
    notice("Error al crear el usuario")
    console.error(e)
    throw e
  })
}

export const updateUser = (setterFunction,params) => {
  var formdata = new FormData();
  Object.keys(params).map(key => {
    if(key === "profile_picture"){
        if(typeof params[key] === "object")
        formdata.append(key, params[key])
    }else{
        formdata.append(key, params[key])
    }
})
  return fetch(`${USERS_URL}/update`,getMultipartParams("PUT",formdata))
  .then(res => handleResponse(res))
  .then(json => {setterFunction(json)
    notice("Usuario actualizado con éxito","success")
  })
  .catch(e => {
    notice("Error al actualizar el usuario")
    console.error(e)
    throw e
  })
}

export const resetPassword = (data) => {
  const jsonData = JSON.stringify(data)

  return fetch(`${USERS_URL}/request_password_reset`, getParams('POST', jsonData))
  .then(res => handleResponse(res))
  .then(json => notice("Se ha solicitado el cambio de contraseña", "success"))
  .catch(e => {
    notice("Error el enviar las instrucciones de cambio de contraseña")
    console.error(e.errors)
    throw e
  })
}