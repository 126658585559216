import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import * as alert from "./duck/alert.duck";
import * as ins_types from "./duck/InsuranceType.duck"
import * as ins_quote from "./duck/InsuranceQuote.duck"
import * as quote from "./duck/quotes.duck"
import * as users from "./duck/users.duck"
import * as blogs from "./duck/blogs.duck"
import * as app from './duck/app.duck';
import * as life_stage from './duck/lifeStage.duck';

export const rootReducer = combineReducers({
  // metronic reducers
  auth: auth.reducer,
  // END metronic reducers
  ins_types: ins_types.reducer,
  alert: alert.reducer,
  ins_quote: ins_quote.reducer,
  quote: quote.reducer,
  user: users.reducer,
  life_stage: life_stage.reducer,
  blog: blogs.reducer,
  app: app.reducer
});
