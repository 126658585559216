import React from 'react'
import { IconButton, Paper, TextField } from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'

export const FilteredSearch = ({ query, setQuery, action }) => {

  return (
    <Paper style={{ width: 400 }}>
      <TextField
        fullWidth
        id="autocomplete_filter_input"
        size="small"
        variant="outlined"
        name="query"
        value={query}
        onChange={e => {
          setQuery(e.target.value)
          if (e.target.value.length >= 3 || e.target.value === "") {
            action(e.target.value);
          }
        }}
        inputProps={{ autoComplete: "off" }}
        InputProps={{
          endAdornment: (
            <>
              {query && <IconButton id="clear_automplete_input_btn" size="small" onClick={() => setQuery("")}>
                <Clear />
              </IconButton>}
              <Search />
            </>
          )
        }}
      />
    </Paper>
  )
}