import React, { useState } from 'react'
import { Grid, Paper, TextField, Button, useMediaQuery, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import moment from "moment"

import { DropNCrop } from '../../../utils/DropNCrop';
import { saveBlog, updateBlog } from '../../crud/api/blogs.crud';

import * as appDuck from '../../../redux/duck/app.duck';
import * as blogDuck from '../../../redux/duck/blogs.duck';
import HTMLEditor from '../../../utils/HTMLEditor';

const BlogInfo = ({ blog, blogActions, appActions }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const normalizeText = text => text.replace(/ñ/g, "ni").normalize("NFD").replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-").toLowerCase();

  const create = values => {
    const data = {
      title: values.title,
      publish_date: moment(values.publish_date).format("YYYY-MM-DD"),
      image: values.image ? values.image : null,
      og_image: values.og_image ? values.og_image : null,
      twitter_image: values.twitter_image ? values.twitter_image : null,
      slug: normalizeText(values.title),
      context: values.context,
      body: values.body,
    }
    
    saveBlog(blogActions.updateBlogs, data)
    .then(() => {setLoading(false);appActions.HideLoader()})
    .catch(() => {setLoading(false);appActions.HideLoader()})
  }

  const update = values => {
    let data = {
      title: values.title,
      publish_date: moment(values.publish_date).format("YYYY-MM-DD"),
      slug: normalizeText(values.title),
      context: values.context,
      body: values.body,
    }
    if (typeof values.image !== "string") data = {...data, image: values.image}
    if (typeof values.og_image !== "string") data = {...data, og_image: values.og_image}
    if (typeof values.twitter_image !== "string") data = {...data, twitter_image: values.twitter_image}
    
    updateBlog(blogActions.updateBlogs, data, blog.id)
    .then(() => {setLoading(false);appActions.HideLoader()})
    .catch(() => {setLoading(false);appActions.HideLoader()})
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...blog,
        publish_date: typeof blog.publish_date === "string" ? moment(blog.publish_date).format("YYYY MM DD") : blog.publish_date,
      }}
      validate={values => {
        const errors = {}

        if (!values.title) errors.title = "Campo requerido"

        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setLoading(true)
        setTimeout(() => {
          appActions.ShowLoader()
          blog.id ? update(values) : create(values)
          setSubmitting(false)
        }, 1000)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
        return (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <div className="pb-4 d-flex justify-content-end align-items-end">
              <Button
                size="large"
                variant="contained"
                onClick={() => history.push("/blog/list")}
              >
                <ArrowBack /> Regresar
              </Button>
              <button
                disabled={isSubmitting}
                className="btn btn-info ml-2 d-flex"
                type="submit"
              >
                Guardar
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    style={{ marginLeft: 10 }}
                  />
                )}
              </button>
            </div>
            <Paper
              className="pt-5 pb-12 px-8"
              style={{
                maxHeight: isDesktop ? "58em" : "45.5em",
                overflow: "auto",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Título"
                    name="title"
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched.title && errors.title}
                    error={Boolean(touched.title && errors.title)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    id="publish_date"
                    label="Fecha de publicación"
                    name="publish_date"
                    format="MMMM dd, yyyy"
                    value={values.publish_date}
                    onChange={(value) => setFieldValue("publish_date", value)}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HTMLEditor
                    content={values.context}
                    setterFunction={setFieldValue}
                    field={"context"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-start"
                  direction="row"
                  alignItems="flex-start"
                >
                  <div>
                    Cover
                    <DropNCrop
                      setterFunction={setFieldValue}
                      field={"image"}
                      file={values.image}
                      style={{ width: "250px" }}
                      aspectRatio={16 / 9}
                    />
                  </div>
                  <div>
                    <center>Imagen para Facebook</center>
                    <DropNCrop
                      setterFunction={setFieldValue}
                      field={"og_image"}
                      file={values.og_image}
                      style={{ width: "150px", marginLeft: isDesktop ? 10 : 2 }}
                      aspectRatio={1 / 1}
                    />
                  </div>
                  <div>
                    <center>Imagen para Twitter</center>
                    <DropNCrop
                      setterFunction={setFieldValue}
                      field={"twitter_image"}
                      file={values.twitter_image}
                      style={{ width: "250px", marginLeft: isDesktop ? 10 : 0 }}
                      aspectRatio={16 / 9}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>Nota</h5>
                  <HTMLEditor
                    content={values.body}
                    setterFunction={setFieldValue}
                    field={"body"}
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        );
      }}
    </Formik>
  )
}

const mapStateToProps = store => {
  return ({
    blog: store.blog.blog,
  })
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  blogActions: bindActionCreators(blogDuck.actions, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(BlogInfo)
