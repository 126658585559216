import React, { useState } from 'react'
import { Grid, Paper,TextField, CircularProgress, Divider, Checkbox, FormControlLabel } from "@material-ui/core"
import { ArrowBack, Save } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { DropNCrop } from '../../../utils/DropNCrop';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { createUser, updateUser } from '../../crud/api/users.crud';

import * as appDuck from '../../../redux/duck/app.duck';
import * as usersDuck from '../../../redux/duck/users.duck';

const SingleUser = ({user, userActions, appActions}) => {   
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    
    return(
        <Formik 
            enableReinitialize
            initialValues={{
                ...user
            }}
            validate={values => {
                const errors = {}
                if (values.first_name === "") errors.first_name = "Campo requerido"
                if (values.last_name === "") errors.last_name = "Campo requerido"
                if(values.email === "") errors.email = 'Campo requerido';
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
                    errors.email = 'Correo invalido';
                
                return errors
            }}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(true);
                setLoading(true);
                if(values.id !== undefined){
                    updateUser(userActions.updateUser,values)
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false))
                    setSubmitting(false);
                }else {
                    createUser(userActions.createUser,values)
                    .then(() => {setLoading(false)
                        history.push("/users/general")
                    })
                    .catch(() => setLoading(false))
                    setSubmitting(false);
                }
            }}>
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting}) => {
            return (
                <Paper className="pt-5 pb-12 px-8" style={{ height: "100%" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>{ values.id === undefined ? "Nuevo Usuario" : "Editar Usuario"}</h5>
                        <div className="d-flex">
                            <button className="btn btn-secondary" onClick={() => history.push("/users/general")}><ArrowBack /> Regresar</button>
                            <button
                                disabled={(values.first_name === "" ||  values.last_name === "" || values.email === "") || isSubmitting}
                                className="btn btn-info ml-2 d-flex"
                                type="submit"
                                onClick={() => handleSubmit()}
                            >
                                <Save color="inherit" size={18} style={{marginRight:10}}/>
                                Guardar                                
                                {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
                            </button>
                        </div>
                    </div>
                    <Divider className="my-4" />
                    <Grid container spacing={2}>                         
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                <Grid container spacing={2} style={{width: "calc(100% - 190px)"}}>
                                    <Grid item xs={12}>
                                        <h5>Datos del usuario</h5>
                                    </Grid>                                    
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Nombre (s)"
                                            name="first_name"
                                            value={values.first_name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.first_name && errors.first_name}
                                            error={Boolean(touched.first_name && errors.first_name)}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Apellidos"
                                            variant="outlined"
                                            name="last_name"
                                            value={values.last_name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.last_name && errors.last_name}
                                            error={Boolean(touched.last_name && errors.last_name)}/>
                                    </Grid>                
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Correo"
                                            variant="outlined"
                                            name="email"
                                            value={values.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}/>
                                    </Grid>                        
                                </Grid>
                                <Grid container spacing={2} style={{width:180}}>
                                    <Grid item xs={12}>
                                        <h5>Foto de perfil</h5>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropNCrop setterFunction={setFieldValue} field={"profile_picture"} file={values.profile_picture} style={{ width: "150px", height: '150px' }}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>   
                        <Grid item xs={12}>
                            <h5>Tipo de usuario</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <FormControlLabel
                                control={<Checkbox color="primary" checked={values.is_admin} onChange={handleChange} name="is_admin" />}
                                label="Administrador"/>
                                <FormControlLabel
                                control={<Checkbox color="primary" checked={values.is_agent} onChange={handleChange} name="is_agent" />}
                                label="Agente"/>
                            </Grid>
                        </Grid>          
                    </Grid>
                </Paper>
            )
        }}
        </Formik>
    )
}

const mapStateToProps = store => {
    return ({
        user: store.user.user
    })
  }
  
const mapDispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(appDuck.actions, dispatch),
    userActions: bindActionCreators(usersDuck.actions, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(SingleUser)