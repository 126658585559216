import React, { Component } from 'react'
import LifeStage from './LifeStage'
import SingleLifeStage from './SingleLifeStage'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '../../../_metronic/layout';

import * as appDuck from '../../../redux/duck/app.duck';
import * as lifeStageDuck from '../../../redux/duck/lifeStage.duck';
import { getLifeStages, getRelatedInsuranceTypes } from '../../crud/api/life_stages.crud';


class LifeStageWrapper extends Component {
  constructor(props){
    super(props);
    props.appActions.ShowLoader();
  }


  componentDidMount() {
    const {appActions, lifeStageActions} = this.props
    getLifeStages(lifeStageActions.setLifeStages)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())      
    getRelatedInsuranceTypes(lifeStageActions.setRelatedInsuranceType)
  }

  render() {
    return (
      <Switch>
        {
          <Redirect exact from="/life_stages" to="/life_stages/general"/>
        }
        <ContentRoute path="/life_stages/general" component={LifeStage}/>
        <ContentRoute path="/life_stages/new" component={SingleLifeStage}/>
        <ContentRoute path="/life_stages/:id/edit" component={SingleLifeStage}/>        
      </Switch>
    )
  }
}

const mapStateToProps = store => {
  return ({
    life_stages: store.life_stage
  })
}

const mapDispatchToProps = (dispatch) => ({
  lifeStageActions: bindActionCreators(lifeStageDuck.actions,dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LifeStageWrapper)