import axios from "axios";
import { handleResponse } from "../../../crud/helpers/validate"
import notice from "../../../../utils/notice"
import { USERS_URL } from "../../../crud/helpers/routes"

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

const getToken = async (email, password) => {
  const data = {
    username: email,
    password: password,
    grant_type: 'password',
    audience: process.env.REACT_APP_AUDIENCE,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  }
  const url = process.env.REACT_APP_OAUTH_TOKEN_URL

  try {
    return await axios.post(url, data)
    .then(res => res.data)
    .catch(e => {
      console.log("Errors",e)
      throw e
    })
  } catch (error) {
    throw error
  }
}

export const login = async (email, password) => {
  try {
    return await getToken(email, password)
    .then(res => {
      if (res.access_token) {
        return fetch(`${USERS_URL}/me`, {
          headers: {
            'Authorization': `Baerer ${res.access_token}`
          }
        })
        .then(response => handleResponse(response))
        .then(json => ({
          user: json,
          authToken: `Baerer ${res.access_token}`
        }))
        .catch(e => {
          notice("Invalid username or password")
          console.error(e.errors)
          throw e
        })
      }
    })
    .catch(e => console.log(e))
  } catch (error) {
    throw error
  }
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
