import { QUOTES_URL , QUOTE_FILES_URL} from "../helpers/routes"
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import notice from "../../../utils/notice"

export const getQuotes = (setterFunction, page = 1,search = "") => {
  return fetch(`${QUOTES_URL}/list_completed_quotes?page=${page}&search=${search}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error cargando las cotizaciones")
    console.log(e.errors)
    throw e
  })
}

export const getQuote = (setterFunction, id) => {
  return fetch(`${QUOTES_URL}/show?q=${id}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error cargando la cotización")
    console.log(e.errors)
    throw e
  })
}

export const updateQuote = (setterFunction, id, data) => {
  const jsonData = JSON.stringify(data)
  
  return fetch(`${QUOTES_URL}/update?quote_uuid=${id}`, getParams('PUT', jsonData))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Cotización actualizada", "success")
    setterFunction(json)
  })
  .catch(e => {
    notice("Error actualizando la cotización")
    console.log(e.errors)
    throw e
  })
}

export const updateFiles = (setterFunction,uuid,files) => {
  var formdata = new FormData();
  formdata.append('uuid',uuid);
  files.forEach((file,index) => {
    formdata.append(`files[${index}]`,file)
  });
  return fetch(`${QUOTES_URL}/upload_files`,getMultipartParams('POST',formdata))
  .then(res => handleResponse(res))
  .then(json => {
    setterFunction(json) 
  })
  .catch(e => {
      console.log(e)
      notice("Error subiendo los archivos")
      throw e
  })
}

export const deleteQuoteFile = (setterFunction,id) => {
  return fetch(`${QUOTE_FILES_URL}/${id}`,getParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => {
    setterFunction(id) 
  })
  .catch(e => {
      console.log(e)
      notice("Error eliminando el archivo")
      throw e
  })
}

export const deleteQuote = (setterFunction,uuid) => {
  return fetch(`${QUOTES_URL}/${uuid}`, getParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error eliminando la cotización")
    console.log(e.errors)
    throw e
  })
}

export const getNewQuotes = (setterFunction) => {
  return fetch(`${QUOTES_URL}/new_quote_count`,getParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error obteniendo notificaciones")
    console.log(e.errors)
    throw e
  })
}

export const updateViewedQuote = (setterFunction,id) => {
  return fetch(`${QUOTES_URL}/viewed_quote?q=${id}`, getParams('PUT'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error actualizando la cotización")
    console.log(e.errors)
    throw e
  })
}

export const downloadCSV = () => {
  return fetch(`${QUOTES_URL}/csv`,getParams('GET'))
  .then(res => handleResponse(res))
  .then(json => {
    let encodedUri = encodeURI(`data:text/csv;charset=utf-8,${json.csv}`);
    let link = document.createElement("a");
    link.setAttribute("href",encodedUri);
    link.setAttribute("download", `cotizaciones_${new Date()}.csv`);
    document.body.appendChild(link);
    link.click();
  })
  .catch(e => {
    notice("Error al generar CSV")
    console.log(e.errors)
    throw e
  })
}