import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik'
import { setAlert } from '../../../redux/duck/alert.duck';

const LifeStageInsurances = ({ related_insurance, deleteRelatedIns, setterFunction, i, setAlert,deleted }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...related_insurance,
      }}
      onSubmit={(values, { setSubmitting }) => console.clear()}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <>
          <Grid key={i} container spacing={1} item xs={12} sm={10}>
            <Grid item xs={12}>
              <TextField
                  fullWidth
                  variant="outlined"
                  label="Tipo de seguro"
                  name="name"
                  value={values.name}
                  disabled/>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={2}>
            <Grid item xs={12}>
              <Button color="secondary" fullWidth onClick={() => {
                values.name
                  ? setAlert({
                    title: "Eliminar tipo de seguro",
                    message: "¿Estás seguro que deseas eliminar el registro?",
                    btn_msg: "Eliminar",
                    action: () => deleteRelatedIns(setterFunction, i,deleted)
                  })
                  : deleteRelatedIns(setterFunction, i,deleted)
              }}>
                Eliminar
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = dispatch => ({
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeStageInsurances)
