/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { MuiThemeProvider, Paper, useMediaQuery, Button } from '@material-ui/core';
import { tableMuiTheme } from '../../table/table_styles';
import MUIDataTable from 'mui-datatables';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import * as insuranceTypeDuck from '../../../redux/duck/InsuranceType.duck';
import * as appDuck from '../../../redux/duck/app.duck';
import * as insQuoteDUCK from "../../../redux/duck/InsuranceQuote.duck";
import { setAlert } from '../../../redux/duck/alert.duck'
import {getInsuranceType, getInsuranceTypes,deleteInsuranceType } from '../../crud/api/insurance_types.crud';
import { getQuoteSlides, getCaptureMechanisms } from '../../crud/api/insurance_quote.crud';


const InsuranceType = ({ insurance_types, insuranceTypeActions, appActions, setAlert, quoteActions }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [query, setQuery] = useState("");

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'name',
      label: 'Seguro',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'slug',
      label: 'Slug',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'slug',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <div className="d-flex">
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
                onClick={() => getSlides(value)}
              >
                <Visibility />
              </a>
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary ml-3"
                onClick={() => getSingleInsuranceType(value)}
              >
                <Edit />
              </a>
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger ml-3"
                onClick={() =>
                  setAlert({
                    title: "Eliminar tipo de seguro",
                    message:
                      "¿Estás seguro que deseas eliminar el tipo de seguro?",
                    btn_msg: "Eliminar",
                    action: () => deleteInsurance(value),
                  })
                }
              >
                <Delete />
              </a>
            </div>
          );
        }
      }
    },
  ]
  const options = {
    serverSide: true,
    responsive: "standard",
    rowsPerPageOptions: [],
    rowsPerPage: insurance_types.data.per_page,
    page: insurance_types.data.page - 1,
    count: insurance_types.data.total,
    search: true,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: "none",
    tableBodyMaxHeight: isDesktop ? "54em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
    downloadOptions: {
      filename: "insurance_types.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayRowsOnly: true,
      },
    },
    onChangePage: (mPage) => {
      appActions.ShowLoader();

      getInsuranceTypes(
        insuranceTypeActions.setInsuranceTypes,
        mPage + 1,
        query
      )
        .then(() => appActions.HideLoader())
        .catch(() => appActions.HideLoader());
    },
    searchText: query,
    onSearchChange: (s) => {
      let search = s === null ? "" : s;
      if (search.length >= 3 || search === "") {
        appActions.ShowLoader();
        getInsuranceTypes(insuranceTypeActions.setInsuranceTypes, 1, search)
          .then(() => appActions.HideLoader())
          .catch(() => appActions.HideLoader());
      }
      setQuery(search);
    },
  };
  const insuranceTypeEvents = {
    newInsuranceType: () =>  {insuranceTypeActions.setInsuranceType(insuranceTypeDuck.defaultInsuranceType); history.push("/insurance_types/new")},
    editInsuranceType: (slug) => history.push(`/insurance_types/${slug}/edit`),
    goToQuote: (slug) => history.push(`/insurance_types/${slug}/insurance_quote`)
  }
  const getSingleInsuranceType = (value) => {
    getInsuranceType(insuranceTypeActions.setInsuranceType,value)
    .then(() => { insuranceTypeEvents.editInsuranceType(value); appActions.HideLoader()})
    .catch(() => appActions.HideLoader())
  }
  const deleteInsurance = (slug) => {
    appActions.ShowLoader();
    deleteInsuranceType(insuranceTypeActions.deleteInsuranceType,slug)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
  }
  const getSlides = (slug) => {
    let insurance = insurance_types.data.items.filter(x => x.slug === slug)[0]
    getInsuranceType(insuranceTypeActions.setInsuranceType, slug)

    getCaptureMechanisms(quoteActions.setCaptureMechanisms)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
    
    getQuoteSlides(quoteActions.setInsQuote,insurance)
    .then(() => { insuranceTypeEvents.goToQuote(slug); appActions.HideLoader()})
    .catch(() => appActions.HideLoader()) 
  }

  return (
    <>
      <div className="pb-4 d-flex justify-content-end align-items-end">
          <Button variant="contained" color="primary" onClick={() => insuranceTypeEvents.newInsuranceType()}>Nuevo Tipo de Seguro</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiTheme()}>
          <MUIDataTable
            title="Tipos de Seguro"
            columns={columns}
            options={options}
            data={insurance_types.data.items}/>
        </MuiThemeProvider>
      </Paper>
    </>
  )
}

const mapStateToProps = store => {
  return({
    insurance_types: store.ins_types.insurance_types
  })
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  insuranceTypeActions: bindActionCreators(insuranceTypeDuck.actions,dispatch),
  quoteActions: bindActionCreators(insQuoteDUCK.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(InsuranceType)
