import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class HTMLEditor extends Component {
    constructor(props) {
        super(props);
        const contentBlock = props.content ? htmlToDraft(props.content) : htmlToDraft("");
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          this.state = {
            editorState,
          };
        }
      }
    
      onEditorStateChange(editorState){          
        this.setState({
          editorState,
        });
      };
    
      render() {
        const { editorState } = this.state;
        return (
          <div>
            <Editor
              editorState={editorState}
              toolbar={{options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}}
              wrapperClassName="html_editor_wrapper"
              editorClassName="html_editor_content"
              onEditorStateChange={this.onEditorStateChange.bind(this)}
              onChange={() => this.props.setterFunction(this.props.field,draftToHtml(convertToRaw(editorState.getCurrentContent())))}
            />
          </div>
        );
      }
}

export default (HTMLEditor)