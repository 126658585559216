import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import notice from "../../../utils/notice"
import { QUOTE_SLIDES_URL, CAPTURE_MECHANISMS_URL, DATA_POINTS_URL } from "../helpers/routes"
import jsonToFormData from '@ajoelp/json-to-formdata';

export const getQuoteSlides = (setterFunction,insurance,page = 1) => {
    return fetch(`${QUOTE_SLIDES_URL}/show_by_id?page=${page}&insurance_type_id=${insurance.id}`, getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json,insurance))
    .catch(e => {
        notice("Error cargando el cotizador")
        console.log(e.errors)
        throw e
    })
}

export const getCaptureMechanisms = (setterFunction,page = 1) => {
    return fetch(`${CAPTURE_MECHANISMS_URL}/?page=${page}`, getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => {setterFunction(json)})
    .catch(e => {
        notice("Error cargando los mecanismos de captura")
        console.log(e.errors)
        throw e
    })
}

export const updateQuoteSlides = (setterFunction,data) => {
    return fetch(`${QUOTE_SLIDES_URL}`, getMultipartParams('POST', jsonToFormData(data,{excludeNull:false})))
    .then(res => handleResponse(res))
    .then(json => {
        setterFunction(json,data.total_quote_steps);
        notice("Cotizador actualizado","success")
    })
    .catch(e => {
        notice("Error actualizando cotizador")
        console.log(e.errors)
        throw e
    })
}

export const deleteDataPoint = (setterFunction,data) => {
    return fetch(`${DATA_POINTS_URL}/${data.id}`,getParams('DELETE'))
    .then(res => handleResponse(res))
    .then(json => {
        setterFunction(data.i,data.j)
        notice("Data point eliminado","success")
    })
    .catch(e => {
        notice("Error eliminando data point.")
        throw e
    })
}
