import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';

import { setAlert } from '../redux/duck/alert.duck';
import { connect } from 'react-redux';

const SuccessAlert = ({ alert, setAlert }) => {

  return (
    <Dialog open={alert.message !== ""} onClose={() => setAlert({ title: "", message: "", btn_msg: "", action: () => { } })} fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center' }}>{alert.title}</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {alert.message}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAlert({ title: "", message: "", btn_msg: "", action: () => { } })}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={() => {
          alert.action()
          setAlert({ title: "", message: "", btn_msg: "", action: () => { } })
        }}>{alert.btn_msg}</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  alert: store.alert.alert
})
function mapDispatchToProps(dispatch) {
  return {
    setAlert: bindActionCreators(setAlert, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessAlert)