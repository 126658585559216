import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ContentRoute } from '../../../_metronic/layout';

import * as appDuck from '../../../redux/duck/app.duck';
import * as quotesDUCK from "../../../redux/duck/quotes.duck"
import { getQuotes , getNewQuotes } from '../../crud/api/quotes.crud'

import Quote from './Quote'
import SingleQuote from './SingleQuote';
class QuoteWrapper extends Component {
  constructor(props){
    super(props)
    props.appActions.ShowLoader();
  }

  componentDidMount() {
    const { quoteActions, appActions } = this.props
    
    getQuotes(quoteActions.setQuotes)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())

    getNewQuotes(quoteActions.setNewQuotes)
  }

  render() {
    return (
      <Switch>
        {
          /* Redirect from root URL to /users. */
          <Redirect exact from="/quotes" to="/quotes/general" />
        }
        <ContentRoute path="/quotes/general" component={Quote} />
        <ContentRoute path="/quotes/new" component={SingleQuote} />
        <ContentRoute path="/quotes/:id/edit" component={SingleQuote} />
      </Switch>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  quoteActions: bindActionCreators(quotesDUCK.actions, dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch)
})

export default connect(null, mapDispatchToProps)(QuoteWrapper)