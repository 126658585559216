import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, useTheme, useMediaQuery, TextField, IconButton } from '@material-ui/core'
import { Delete } from "@material-ui/icons"
import { Formik } from 'formik';

import { DropNCrop } from '../../../../utils/DropNCrop';
import { setAlert } from '../../../../redux/duck/alert.duck';

const Testimony = ({ testimony, i, setterFunction, deleteTestimony, setAlert,deleted }) => {
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...testimony
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <Grid container spacing={1} direction={isMobile ? "column-reverse" : "row"} style={{ marginBottom: "2vh" }}>
          <Grid container spacing={1} item xs={12} sm={10}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nombre del cliente"
                name="person_name"
                value={values.person_name}
                onChange={(e) => setterFunction(`testimonials[${i}]['person_name']`,e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Testimonio"
                name="content"
                value={values.content}
                onChange={(e) => setterFunction(`testimonials[${i}]['content']`,e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12} sm={2}>
            <Grid container item justify="center" alignItems="center" xs={6}>
              <DropNCrop setterFunction={setterFunction} field={`testimonials[${i}]['person_avatar']`} file={values.person_avatar} style={{ width: "100%", height: '100px' }}/>
            </Grid>
            <Grid container item justify="center" alignItems="center" xs={6}>
              <IconButton onClick={() => {
                values.person_name
                  ? setAlert({
                    title: "Eliminar testimonio",
                    message: "¿Estás seguro que deseas eliminar el registro?",
                    btn_msg: "Eliminar",
                    action: () => deleteTestimony(i, setterFunction,deleted)
                  })
                  : deleteTestimony(i, setterFunction,deleted)
              }}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = dispatch => ({
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Testimony)
