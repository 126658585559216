import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultQuote = {
  client_first_name: "",
  client_last_name: "",
  data_point_answers: [],
  insurance_type: null,
  quote_answers: [],
  quote_slides: [],
  quote_files: [],
  uuid: "",
  agent: null,
}

export const actionType = {
  GetQuotes: '[QUOTE] GET_QUOTES',
  GetQuote: '[QUOTE] GET_QUOTE',
  UpdateQuote: '[QUOTE] UPDATE_QUOTES',
  UploadFiles: '[QUOTE] UPLOAD_FILES',
  DeleteQuote: '[QUOTE] DELETE_QUOTE',
  SetNewQuotes: '[QUOTE] SET_NEW_QUOTES',
  UpdateViewedQuote: '[QUOTE] UPDATE_VIEWED_QUOTE'
}

const initialState = {
  quotes: { data: [], isFetched: false },
  quote: defaultQuote,
  new_quotes: 0
}

export const reducer = persistReducer(
  { storage, key: 'quote', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetQuotes: {
        return { ...state, quotes: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateQuote: {
        let newData = state.quotes.data
        const index = state.quotes.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.quotes.data]
        } else {
          newData = state.quotes.data
          newData[index] = action.payload
        }

        return { ...state, quotes: { ...state.quotes, data: newData } }
      }

      case actionType.GetQuote: {

        return { ...state, quote: action.payload }
      }

      case actionType.UploadFiles: {

        return { ...state, quote: {...state.quote, quote_files: action.payload} }
      }

      case actionType.DeleteQuote: {
        let newData = state.quotes.data.filter((x) => x.uuid !== action.payload.uuid )
        return {...state, quotes: {...state.quotes, data: newData}, isFetched: true}
      }

      case actionType.SetNewQuotes: {
        return {...state, new_quotes: action.payload}
      }

      case actionType.UpdateViewedQuote: {
        let newData = state.quotes.data
        const index = state.quotes.data.findIndex(x => x.id === action.payload.id)        
        newData = state.quotes.data
        newData[index] = {...state.quotes.data[index], new: false}
        
        return { ...state, quotes: { ...state.quotes, data: newData } }
      }

      default: return state

    }
  }
)

export const actions = {
  setQuotes: data => ({ type: actionType.GetQuotes, payload: data }),
  setQuote: data => ({ type: actionType.GetQuote, payload: data }),
  updateQuotes: data => ({ type: actionType.UpdateQuote, payload: data }),
  uploadFiles: data => ({type: actionType.UploadFiles, payload: data }),
  deleteQuote: data => ({type: actionType.DeleteQuote, payload: data}),
  setNewQuotes: data => ({type: actionType.SetNewQuotes, payload: data}),
  updateViewedQuote: data => ({type: actionType.UpdateViewedQuote, payload: data})
}