/* eslint-disable no-throw-literal */
import store from '../../../redux/store';
export function getToken(){
    return store.getState().auth.authToken
}

export function getParams(method, body = null){
    return {
        method: method,
        body: body,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    }
}

export function getMultipartParams(method, body = null){
    return {
        method: method,
        body: body,
        headers: {
            'Authorization': getToken()
        }
    }
}

export async function handleResponse(response){
    if (!response.ok) {
        if (response.status === 401){
            store.dispatch({type: '[Logout] Action'})
            throw { errors: 'Sesion expirada' }
        } else if (response.status === 422){
            throw await response.json()
        }
        throw { errors: response.statusText }
    }
    return response.statusText === "No Content" ? {} :response.json()
}