import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, useTheme, useMediaQuery, TextField, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons';
import { Formik } from 'formik';
import { setAlert } from '../../../../redux/duck/alert.duck';

const FunFact = ({ fun_fact, setterFunction, i, deleteFunFact, setAlert,deleted }) => {
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...fun_fact
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <Grid container spacing={1} direction={"row"} style={{ marginBottom: "2vh" }}>
          <Grid container spacing={1} item xs={10} sm={11} justify="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Fun fact"
                name="content"
                value={values.content}
                onChange={(e) => setterFunction(`fun_facts[${i}]['content']`,e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={2} sm={1} justify="center">
            <Grid container item justify="center" alignItems="center" xs={12}>
              <IconButton onClick={() => {
                values.content
                  ? setAlert({
                    title: "Eliminar fun fact",
                    message: "¿Estás seguro que deseas eliminar el registro?",
                    btn_msg: "Eliminar",
                    action: () => deleteFunFact(i, setterFunction,deleted)
                  })
                  : deleteFunFact(i, setterFunction,deleted)
              }}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = dispatch => ({
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FunFact)
