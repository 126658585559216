/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import * as Menu from "../../../MenuConfig"
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';

const AsideMenuList = ({ layoutProps, new_quotes }) => {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          {Menu.default.aside.items.map(item => {
          return (
            <li
              key={item.title}
              className={`menu-item ${getMenuItemActive(item.page, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={item.page}>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(`/media/svg/icons/${item.icon}`)} />
                </span>                
                {item.title === "Cotizaciones" ? 
                  <Badge badgeContent={new_quotes} color="secondary">
                    <span className="menu-text" style={{paddingRight:10}} >
                      {item.title}                  
                    </span>
                  </Badge>
                  : 
                  <span className="menu-text">
                    {item.title}                  
                  </span>
                }
              </NavLink>
            </li>
          )
        })}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}

const mapStateToProps = store => ({
  new_quotes: store.quote.new_quotes,
})
export default connect(mapStateToProps,null)(AsideMenuList)
