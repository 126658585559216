/* eslint-disable array-callback-return */
import { LIFE_STAGES_URL, MIN_LIFE_STAGES_URL, LS_INSURANCE_TYPES_URL } from "../helpers/routes"
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import notice from "../../../utils/notice"

export const getLifeStages = (setterFunction,page = 1,search = "") => {
    return fetch(`${MIN_LIFE_STAGES_URL}?page=${page}&search=${search}`,getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error cargando las etapas de vida")
        throw e
    })
}

export const getLifeStage = (setterFunction,id) => {
    return fetch(`${LIFE_STAGES_URL}/show?id=${id}`,getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error cargando etapa de vida")
        throw e
    })
}

export const getRelatedInsuranceTypes = (setterFunction,page = 1) => {
    return fetch(`${LS_INSURANCE_TYPES_URL}?page=${page}`,getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error cargando los tipos de seguro")
        throw e
    })
}

export const updateLifeStage = (setterFunction,params) => {
    var formdata = new FormData();
    Object.keys(params).map(key => {
        if(key === "insurance_types" || key === "deleted"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "image"){
            if(typeof params[key] === "object")
            formdata.append(key, params[key])
        }else{
            formdata.append(key, params[key])
        }
    })
    return fetch(`${LIFE_STAGES_URL}/update`,getMultipartParams('PUT',formdata))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error actualizando etapa de vida")
        throw e
    })
}

export const createLifeStage = (setterFunction,params) => {
    var formdata = new FormData();
    Object.keys(params).map(key => {
        if(key === "insurance_types"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "image"){
            if(typeof params[key] === "object")
            formdata.append(key, params[key])
        }else{
            formdata.append(key, params[key])
        }
    })
    return fetch(`${LIFE_STAGES_URL}`,getMultipartParams('POST',formdata))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error creando etapa de vida")
        throw e
    })
}
export const deleteLifeStage = (setterFunction,id) => {
    return fetch(`${LIFE_STAGES_URL}/${id}`,getParams('DELETE'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error eliminando etapa de vida")
        throw e
    })
}