const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const USERS_URL = `${BASE_URL}/users`
export const QUOTES_URL = `${BASE_URL}/quotes`
export const LIFE_STAGES_URL = `${BASE_URL}/life_stages`
export const MIN_LIFE_STAGES_URL = `${BASE_URL}/min_life_stages`
export const LS_INSURANCE_TYPES_URL = `${BASE_URL}/insurance_types/no_children`
export const BLOGS_URL = `${BASE_URL}/articles`
export const INSURANCE_TYPES_URL = `${BASE_URL}/insurance_types`
export const QUOTE_FILES_URL = `${BASE_URL}/quote_files`
export const QUOTE_SLIDES_URL = `${BASE_URL}/quote_slides`
export const CAPTURE_MECHANISMS_URL = `${BASE_URL}/capture_mechanisms`
export const DATA_POINTS_URL = `${BASE_URL}/data_points`


export const DOWNLOAD_QUOTES_PDF_URL = `${BASE_URL}/documents/consult_document`