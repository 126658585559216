/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Button, MuiThemeProvider, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { CloudDownload, Delete, Visibility, PictureAsPdfSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';

import axios from 'axios';

import { tableMuiThemeNoToolbar } from "../../table/table_styles";

import * as appDuck from '../../../redux/duck/app.duck';
import * as quotesDUCK from "../../../redux/duck/quotes.duck"
import { deleteQuote, getQuote, getQuotes , downloadCSV } from '../../crud/api/quotes.crud';
import { FilteredSearch } from '../../../utils/FilteredSearch';
import { setAlert } from '../../../redux/duck/alert.duck';

import { DOWNLOAD_QUOTES_PDF_URL } from '../../crud/helpers/routes';

const Quote = ({ quotes, quoteActions, appActions, setAlert }) => {
  const theme8 = useTheme();
  const isMobile = useMediaQuery(theme8.breakpoints.down("xs"));

  const history = useHistory()
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [query, setQuery] = useState("")

  function downloadFileBase64toPDF(tipo, archivo) {
    // Decodificar el archivo base64
    const pdfData = atob(tipo);
  
    // Crear un array de bytes
    const byteNumbers = new Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      byteNumbers[i] = pdfData.charCodeAt(i);
    }
  
    // Crear un Blob a partir del array de bytes
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Crear un enlace y simular un clic en él para iniciar la descarga
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${archivo}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  //TODO: Download policy and cotization
  const downloadPolicy = async(id, TYPE) => {
    appActions.ShowLoader()
    console.log('descargar poliza',id)
    try {
      const response = await axios.post(DOWNLOAD_QUOTES_PDF_URL, { tipo: TYPE, policyNumber: id});
      console.log('response', response);
      downloadFileBase64toPDF(response.data.document, TYPE);
      appActions.HideLoader()

    } catch (error) {
      appActions.HideLoader()
      console.error('Error al descargar la póliza', error);
    }
  }
  const quoteEvents = {
    newQuote: () => history.push("/quotes/new"),
    editQuote: (id) => history.push(`/quotes/${id}/edit`),
  }

  const getSingleQuote = (uuid) => {
    appActions.ShowLoader()
      getQuote(quoteActions.setQuote, uuid)
      .then(() => {quoteEvents.editQuote(uuid); appActions.HideLoader()})
      .catch(() => appActions.HideLoader())
  }

  const removeQuote = (uuid) => {
    appActions.ShowLoader();
    deleteQuote(quoteActions.deleteQuote,uuid)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
}

  const searchQuotes = (search) => {
    getQuotes(quoteActions.setQuotes, 1, search)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader());
  }

  const getQuotesCSV = () => {
    appActions.ShowLoader();
    downloadCSV()
      .then(() => {
        appActions.HideLoader();
      })
      .catch(() => appActions.HideLoader());
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'created_at_str',
      label: 'Fecha y hora',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'insurance_type',
      label: 'Producto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              {value.name}
            </>
          )
        }
      }
    },
    {
      name: 'client_first_name',
      label: 'Nombre del cliente',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, metaData) => {
          
          return `${value} ${metaData.tableData[metaData.rowIndex].client_last_name}`
        }
      }
    },
    {
      name: 'completed',
      label: 'Cotización terminada',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, metaData, updateValue) => {
          
          return (
            <span className={`badge badge-pill badge-${value ? "success" : "secondary"}`}>{value ? "Si" : "No"}</span>
          )
        }
      }
    },
    {
      name: 'agent',
      label: 'Agente',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              {value.first_name} {value.last_name}
            </>
          )
        }
      }
    },
    {
      name: 'ala_azul_policy_numbers',
      label: 'Cotización',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              {console.log(value)}
              {value && Array.isArray(value) && value.length > 0 && value[0].quotation &&
              (
                <div className="d-flex">
                <a
                  className="btn btn-icon btn-sm btn-light btn-hover-primary text-danger"
                  onClick={()=>downloadPolicy(value[0].quotation, 'COTIZACION')}
                >
                  <PictureAsPdfSharp />
                </a>
            </div>
              )
              }
            </>
          )
        }
      }
    },
    {
      name: 'ala_azul_policy_numbers',
      label: 'Poliza',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              {console.log(value)}
              {value && Array.isArray(value) && value.length > 0 &&  value[0].payment &&
              (
                <div className="d-flex">
                <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary text-danger"
                onClick={()=>downloadPolicy(value[0].payment, 'PAGO')}
              >
                <PictureAsPdfSharp />
              </a>
            </div>
              )
              }
            </>
          )
        }
      }
    },
    {
      name: 'uuid',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <div className="d-flex">
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
                onClick={() => getSingleQuote(value)}
              >
                <Visibility />
              </a>
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger ml-3"
                onClick={() =>
                  setAlert({
                    title: "Eliminar cotización",
                    message: "¿Estás seguro que deseas eliminar lacotización?",
                    btn_msg: "Eliminar",
                    action: () => removeQuote(value),
                  })
                }
              >
                <Delete />
              </a>
            </div>
          );
        }

      }
    },
    {
      name: 'new',
      label: 'new',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ]

  const options = {
    serverSide: true,
    responsive: 'standard',
    rowsPerPageOptions: [],
    rowsPerPage: quotes.rowsPerPage,
    page: quotes.page,
    count: quotes.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    rowHover: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "54em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
    downloadOptions: {
      filename: "quotes.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: mPage => {
      appActions.ShowLoader()

      getQuotes(quoteActions.setQuotes, mPage +1, query)
      .then(() => appActions.HideLoader())
      .catch(() => appActions.HideLoader())
    },
    setRowProps: row => {
      if(row[6])
       return {style: {backgroundColor: "#EEE5FF"}}
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <FilteredSearch query={query} setQuery={setQuery} action={searchQuotes}/>
        {!isMobile ? (
          <Button size="large" color="primary" variant="contained" onClick={() => getQuotesCSV()}>
            <CloudDownload className="mr-2" />
            Descargar cotizaciones
          </Button>
        ) : (
          <></>
        )}
      </div>
      {isMobile ? (
        <div className="d-flex justify-content-between align-items-center">
          <center style={{width:"100%", marginBottom:10}}>
            <Button size="large" color="primary" variant="contained" onClick={() => getQuotesCSV()}>
              <CloudDownload className="mr-2" />
              Descargar cotizaciones
            </Button>
          </center>
        </div>
      ) : (
        <></>
      )}
      <Paper style={{ /*borderTop: "#4b4e5c solid 5px"*/ }}>
        <MuiThemeProvider theme={tableMuiThemeNoToolbar()}>
          <MUIDataTable
            title=""
            columns={columns}
            options={options}
            data={quotes.data}
          />
        </MuiThemeProvider>
      </Paper>
    </>
  );
}

const mapStateToProps = store => ({
  quotes: store.quote.quotes,
})

const mapDispatchToProps = (dispatch) => ({
  quoteActions: bindActionCreators(quotesDUCK.actions, dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Quote)
