/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Paper, MuiThemeProvider, Button, useMediaQuery } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import MUIDataTable from 'mui-datatables';
import { connect } from "react-redux";

import { tableMuiTheme } from '../../table/table_styles';

import * as appDuck from '../../../redux/duck/app.duck';
import * as blogDuck from '../../../redux/duck/blogs.duck';
import { setAlert } from '../../../redux/duck/alert.duck'
import { getBlog, getBlogs, deleteBlog } from '../../crud/api/blogs.crud';

const Blog = ({ appActions, blogs, blogActions, setAlert }) =>{
    const history = useHistory()
    const isDesktop = useMediaQuery("(min-width: 960px)")
    const [query, setQuery] = useState("");

    const blogEvents = {
        newBlog: () => {
            appActions.ShowLoader()
            blogActions.setBlog(blogDuck.defaultBlog)
            setTimeout(() => {
                appActions.HideLoader()
                history.push("/blog/new")
            }, 500);
        },
        editBlog: (slug) => {
            appActions.ShowLoader()
            getBlog(blogActions.setBlog, slug)
            .then(() => {
                appActions.HideLoader()
                history.push(`/blog/${slug}/edit`)
            })
            .catch(() => appActions.HideLoader())
        },
      }

    const columns = [
        {
            name: 'title',
            label: 'Titulo',
            options:{
                filter: false,
                sort: false
            }
        },
        {
            name: 'publish_date',
            label: 'Fecha',
            options:{
                filter: false,
                sort: true
            }
        },
        {
            name: 'slug',
            label: 'Acciones',
            options:{
                filter: false,
                sort: false,
                customBodyRender: value => {

                    return (
                      <div className="d-flex">
                        <a
                          className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
                          onClick={() => blogEvents.editBlog(value)}
                        >
                          <Visibility />
                        </a>
                        <a
                          className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger ml-3"
                          onClick={() =>
                            setAlert({
                              title: "Eliminar artículo",
                              message:
                                "¿Estás seguro que deseas eliminar el artículo?",
                              btn_msg: "Eliminar",
                              action: () => removeBlog(value),
                            })
                          }
                        >
                          <Delete />
                        </a>
                      </div>
                    );
                }
            }
        },
    ];

    const options = {
        serverSide: true,
        responsive: 'standard',
        rowsPerPageOptions: [],
        rowsPerPage: blogs.rowsPerPage,
        page: blogs.page,
        count: blogs.count,
        search: true,
        sort: false,
        print: false,
        viewColumns: false,
        download: false,
        rowHover: false,
        selectableRows: 'none',
        tableBodyMaxHeight: isDesktop ? "50em" : "357px",
        textLabels: {
          body: {
            noMatch: "No data"
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas:",
            displayRows: "de",
          },
        },
        downloadOptions: {
          filename: "blogs.csv",
          filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
        },
        onChangePage: mPage => {
            appActions.ShowLoader()

            getBlogs(blogActions.setBlogs, mPage +1,query)
            .then(() => appActions.HideLoader())
            .catch(() => appActions.HideLoader())
        },
        searchText: query,
        onSearchChange: (s) => {
            let search = s === null ? "" : s
            if (search.length >= 3 || search === "") {
                appActions.ShowLoader();
                getBlogs(blogActions.setBlogs,1,search)
                .then(() => appActions.HideLoader())
                .catch(() => appActions.HideLoader());
            }
            setQuery(search)
        }
    };

    const removeBlog = (slug) => {
        appActions.ShowLoader();
        deleteBlog(blogActions.deleteBlog,slug)
        .then(() => appActions.HideLoader())
        .catch(() => appActions.HideLoader())
    }

    return(
        <>
            <div className="pb-4 d-flex justify-content-end align-items-end">
                <Button variant="contained" color="primary" onClick={() => blogEvents.newBlog()}>Nuevo artículo</Button>
            </div>
            <Paper>
                <MuiThemeProvider theme={tableMuiTheme()}>
                    <MUIDataTable
                    title="Blogs"
                    columns={columns}
                    data={blogs.data}
                    options={options}
                    />
                </MuiThemeProvider>
            </Paper>
        </>
    )
}

const mapStateToProps = store => {
    return ({
        blogs: store.blog.blogs,
    })
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  blogActions: bindActionCreators(blogDuck.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(Blog)