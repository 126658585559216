export const actionType = {
  ShowLoader: "[APP] SHOW_LOADER",
  HideLoader: "[APP] HIDE_LOADER"
};

const initialState = {
  showLoader: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ShowLoader: {
      return { ...state, showLoader: action.payload };
    }
    case actionType.HideLoader: {
      return { ...state, showLoader: action.payload };
    }
    default:
      return state;

  }
};

export const actions = {
  ShowLoader: () => ({ type: actionType.ShowLoader, payload: true }),
  HideLoader: () => ({ type: actionType.HideLoader, payload: false }),
};