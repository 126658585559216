import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import { resetPassword } from '../../crud/api/users.crud'

const PasswordReset = ({ email, setEmail }) => {
  const [loading, setLoading] = useState(false)
  const open = Boolean(email)

  return (
    <Dialog open={open} onClose={() => setEmail("")} fullWidth maxWidth="sm" id="user_pass_reset_dialog">
      <DialogTitle>
        Password Reset
      </DialogTitle>
        <Formik
          initialValues={{
            email_confirmation: "",
          }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true)
          setTimeout(() => {
            setSubmitting(false)
            resetPassword({email: email}) 
            .then(() => {setEmail(""); setLoading(false)})
            .catch(() => setLoading(false))
          }, 1000)
        }}
        validate={values => {
          const errors = {}
          if (email !== values.email_confirmation) errors.email_confirmation = "Emails must be the same"
          return errors
        }}
        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    id="pass_reset_email_input_dialog"
                    variant="outlined"
                    type="email"
                    label="Email"
                    name="email"
                    value={email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="pass_reset_email_confirm_input_dialog"
                    variant="outlined"
                    type="email"
                    label="Confirm email"
                    name="email_confirmation"
                    value={values.email_confirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email_confirmation && errors.email_confirmation}
                    error={Boolean(touched.email_confirmation && errors.email_confirmation)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button id="cancel_pass_reset_dialog" onClick={() => setEmail("")}>Cancel</Button>
              <button
                type="submit"
                id="submit_pass_reset_dialog"
                disabled={isSubmitting || email !== values.email_confirmation}
                className="btn btn-primary btn-elevate kt-login__btn-primary d-flex">
                Trigger password reset
                {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
              </button>
            </DialogActions>
          </form>)}
        </Formik>
    </Dialog>
  )
}

export default PasswordReset