export default {
  aside: {
    self: {},
    items: [
      {
        title: "Usuarios",
        root: true,
        icon: "General/User.svg",
        page: "/users"
      },
      {
        title: "Cotizaciones",
        root: true,
        icon: "General/Half-heart.svg",
        page: "/quotes"
      },
      {
        title: "Etapas de la vida",
        root: true,
        icon: "Home/Flower2.svg",
        page: "/life_stages"
      },
      {
        title: "Blog",
        root: true,
        icon: "Design/Edit.svg",
        page: "/blog"
      },
      {
        title: "Tipos de seguro",
        root: true,
        icon: "Files/Folder-heart.svg",
        page: "/insurance_types"
      }
    ]
  }
};