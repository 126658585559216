import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultUser = {
  first_name: "",
  last_name: "",
  email: "",
  profile_picture: "", 
  is_admin: false,  
  is_agent: true
}

export const actionType = {
  GetUsers: '[USER] GET_USERS',
  GetUser: '[USER] GET_USER',
  UpdateUser: '[USER] UPDATE_USERS',
  CreateUser: '[USER] CREATE_USER'
}

const initialState = {
  users: { data: [], isFetched: false },
  user: defaultUser
}

export const reducer = persistReducer(
  { storage, key: 'user', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetUsers: {
        return { ...state, users: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateUser: {
        let newData = state.users.data
        const index = state.users.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.users.data]
        } else {
          newData = state.users.data
          newData[index] = action.payload
        }

        return { ...state, users: { ...state.users, data: newData } }
      }

      case actionType.GetUser: {

        return { ...state, user: action.payload }
      }

      case actionType.CreateUser: {
        let newData = [...state.users.data]
                newData.push(action.payload)
                return {...state, users: { ...state.users, data: newData, isFetched: true}, user: action.payload }
      }

      default: return state

    }
  }
)

export const actions = {
  setUsers: data => ({ type: actionType.GetUsers, payload: data }),
  setUser: data => ({ type: actionType.GetUser, payload: data }),
  updateUser: data => ({ type: actionType.UpdateUser, payload: data }),
  createUser: data => ({ type: actionType.CreateUser, payload: data })
}