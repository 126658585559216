import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultInsQuote = {
  name: "",
  color: "",
  logo: "",
  slides: [],
}

export const actionType = {
  GetInsQuotes: '[INS_QUOTE] GET_INS_QUOTES',
  GetInsQuote: '[INS_QUOTE] GET_INS_QUOTE',
  UpdateInsQuote: '[INS_QUOTE] UPDATE_INS_QUOTES',
  GetCaptureMechanisms: '[INS_QUOTE] GET_CAPTURE_MECHANISMS',
  ResetInsQuote: '[INS_QUOTE] RESET_INS_QUOTE',
  UpdateInsQuoteSlides: '[INS_QUOTE] UPDATE_INS_QUOTE_SLIDES'
}

const initialState = {
  ins_quotes: { data: [], isFetched: false },  
  ins_quote: defaultInsQuote,
  capture_mechanisms: {data: [], isFetched: false}
}

export const reducer = persistReducer(
  { storage, key: 'ins_quote', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetInsQuotes: {
        return { ...state, ins_quotes: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateInsQuote: {
        let newData = state.ins_quotes.data
        const index = state.ins_quotes.data.findIndex(x => x.id === action.payload.id)

        if (index === -1) {
          newData = [action.payload, ...state.ins_quotes.data]
        } else {
          newData = state.ins_quotes.data
          newData[index] = action.payload
        }

        return { ...state, ins_quotes: { ...state.ins_quotes, data: newData } }
      }

      case actionType.GetInsQuote: {
        let quote = {...action.insurance_type, slides: [], results: [], status: true}
        
        action.payload.items.sort((a,b) => { return a.display_index -b.display_index }).forEach(element => {
          quote.slides.push(element)
        });

        if(quote.slides.length === 0){
          let initialSlides = {
            head: "",
            context: "",
            dp_ids: [],
            data_points: [],
            display_index: 1
          }
          quote.slides.push(initialSlides)
        }

        return { ...state, ins_quote: quote }
      }

      case actionType.GetCaptureMechanisms: {
        return { ...state, capture_mechanisms: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.ResetInsQuote: {
        return { ...state, ins_quote: defaultInsQuote }
      }

      case actionType.UpdateInsQuoteSlides: {   
        let quote = {...state.ins_quote, slides: []}              
        action.payload.sort((a,b) => { return parseInt(a.display_index) - parseInt(b.display_index) }).forEach(element => {          
          element.display_index = parseInt(element.display_index)
          quote.slides.push(element)
        });
        quote.total_quote_steps = action.total_quote_steps
        
        return { ...state, ins_quote: quote}                      
      }

      default: return state

    }
  }
)

export const actions = {
  setInsQuotes: data => ({ type: actionType.GetInsQuotes, payload: data }),
  setInsQuote: (data,insurance) => ({ type: actionType.GetInsQuote, payload: data , insurance_type: insurance }),
  updateInsQuotes: data => ({ type: actionType.UpdateInsQuote, payload: data }),
  setCaptureMechanisms: data => ({ type: actionType.GetCaptureMechanisms, payload: data }),
  resetInsQuote: () => ({ type: actionType.ResetInsQuote}),
  updateInsQuoteSlides: (data,total_quote_steps) => ({type: actionType.UpdateInsQuoteSlides, payload: data, total_quote_steps: total_quote_steps })
}