import React, { useState } from 'react'
import { Grid, Typography, Button, TextField, FormControl, InputLabel, MenuItem, Checkbox, Paper, Divider, Dialog, DialogTitle, DialogContent, FormControlLabel, InputAdornment, OutlinedInput, IconButton } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setAlert } from '../../../redux/duck/alert.duck'
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { AddCircle, Delete } from '@material-ui/icons'
import { DropNCrop } from '../../../utils/DropNCrop'
import { Formik } from 'formik'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { deleteDataPoint } from '../../crud/api/insurance_quote.crud';

const theme = createMuiTheme(
  {
    typography: {
      fontFamily: ["Roboto"].join(","),
      fontWeight: 500
    },

    overrides: {
      MuiInputLabel: {
        root: {
          fontFamily: "Roboto",
          fontWeight: 500
        }
      },
    }
  }
);

const IQQuestionary = ({  slides, setSlides, capture_mechanisms, deleted, setDeleted, setAlert, setShouldSort, should_sort, total_quote_steps,setTotalQuoteSteps, setDisabled }) => {
  var _ = require('lodash');
  const [openDialog,setOpenDialog] = useState({open: false, variant: "",  slide: 0, data_point: 0})
  const defaultCardProps = {value: "",display_text: "", image: null, next_index: 1}

  const handleChange = (value, index, field) => {
    let newValues = slides
    newValues[index][field] = value
    if (field === "finish" && !value){
      newValues[index]["additional"] = false
    }
    setSlides([...newValues])
    setDisabled(false)    
  }
  const normalizeText = text => text.replace(/ñ/g, "ni").replace(/ /g, "_").normalize("NFD").replace(/[^a-zA-Z0-9_]/g, "").toLowerCase();
  const onDataPointChange = (index, mIndex, value, field) => {
    let newMechs = slides

    if(field === "capture_mechanism"){
      let mechanism = capture_mechanisms.data.filter(x => x.key === value)[0]
      newMechs[index]["data_points"][mIndex][field] = mechanism
      switch(mechanism.key){
        case "card_selection":
          newMechs[index]["data_points"][mIndex]["props"] = { options: [defaultCardProps]}
        break;
        case "dropdown":
          newMechs[index]["data_points"][mIndex]["props"] = {options: [], next_index: 1}
        break;
        default:
          newMechs[index]["data_points"][mIndex]["props"] = { mask: ""}
        break;
      }
     
    }else {
      newMechs[index]["data_points"][mIndex][field] = value
      newMechs[index]["data_points"][mIndex]["key"] = normalizeText(value)
    }
    setSlides([...newMechs])
    setDisabled(false)
  }

  const addNewDataPoint = (index) => {
    let newSlides = slides
    let newDP = newSlides[index]["data_points"]
    newDP.push({ name: "", key: "", capture_mechanism: capture_mechanisms.data[0], props: {},display_index: newDP.length+1})
    newSlides[index]["data_points"] = newDP

    setSlides([...newSlides])
  }

  const deleteDatapoint = (i,j) => {
    let newSlides = slides
    let d = newSlides[i]["data_points"].splice(j,1)[0]

    setSlides([...newSlides])
  }

  const verifyDefault = (props) => {
    return _.isEqual(props,{ options: [defaultCardProps]}) || _.isEqual(props,{options: [], next_index: 1}) || _.isEqual(props,{ mask: ""}) || _.isEqual(props,{})
  }
  const deleteSlide = index => {
    const newDeleted = deleted
    const newSlides = slides
    let slide = newSlides.splice(index, 1)[0]
    if(slide.id !== undefined)
      newDeleted.push(slide.id)

    setSlides([...newSlides])
    setDeleted(newDeleted)
    setDisabled(false)
  }
  const addNewSlide = () => {
    let newValues = slides
    let initialSlides = {
      head: "",
      context: "",
      data_points: [],
      display_index: slides.length > 0 ? slides[slides.length-1].display_index + 1 : 0
    }

    newValues.push(initialSlides)
    setSlides([...newValues])
    setDisabled(false)
  }

  const renderConfigration = (variant) => {    
    switch(variant){
      case "card_selection":
        return (
          <Formik 
                initialValues={{
                  ...slides[openDialog.slide]["data_points"][openDialog.data_point]["props"]
                }}
                onSubmit={(values,setSubmiting) => {
                  let newSlides = slides
                  newSlides[openDialog.slide]["data_points"][openDialog.data_point]["props"] = values
                  setSlides([...newSlides])
                  setDisabled(false)
                  setOpenDialog({open: false, variant: "",  slide: 0, data_point: 0})
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (             
                  <Grid container spacing={2}> 
                    <Grid item xs={12}>
                      {
                        values.options.map((card,i) => (
                          <div key={`Card_${i}`}>
                            <Grid container spacing={2} elevation={2}>
                              <Grid item xs={12}>Opción {i+1}</Grid>
                              <Grid item xs={9}>
                                <Grid container spacing={2}>
                                  <Grid xs={6} item>
                                    <TextField 
                                      fullWidth
                                      label="Valor"
                                      variant="outlined" margin="dense"
                                      value={card.value}
                                      onChange={(e) => setFieldValue(`options[${i}]["value"]`,e.target.value)}/>
                                  </Grid>
                                  <Grid xs={12} item>
                                    <TextField 
                                      fullWidth
                                      variant="outlined" margin="dense"
                                      label="Texto Mostrado"
                                      value={card.display_text}
                                      onChange={(e) => setFieldValue(`options[${i}]["display_text"]`,e.target.value)}/>
                                  </Grid>
                                  <Grid xs={6} item>
                                    <TextField 
                                      fullWidth
                                      variant="outlined" margin="dense"
                                      label="Siguiente pregunta"
                                      value={card.next_index}
                                      onChange={(e) => setFieldValue(`options[${i}]["next_index"]`,e.target.value)}/>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                  <DropNCrop setterFunction={setFieldValue} field={`options[${i}]["image"]`} file={card.image} style={{ width: 150, height: 150 , marginRight: 10}}/>
                                  <IconButton onClick={() => {
                                    let newCards = values.options
                                    newCards.splice(i,1)
                                    setFieldValue("options",newCards)  
                                  }}>
                                    <Delete/>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                            {
                              i < values.options.length -1 ? <Divider style={{marginBottom:10, marginTop:10}}/> : ""
                            }
                          </div>
                        ))
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <center>
                        <Button variant="contained" color="primary" onClick={() => { 
                          let newCards = values.options
                          newCards.push(defaultCardProps)
                          setFieldValue("options",newCards)                      
                        }}>
                            Agregar opción
                        </Button>
                      </center>
                    </Grid>
                    <Grid item xs={12}>
                      <Button className="kt-login__btn-primary" color="primary" variant="contained" type="submit" onClick={handleSubmit} style={{float: "right"}}>Guardar</Button>
                    </Grid>
                  </Grid>          
                )}
              </Formik>
        )
      case "dropdown":
        return (
          <Formik 
            initialValues={{
              ...slides[openDialog.slide]["data_points"][openDialog.data_point]["props"]
            }}
            onSubmit={(values,setSubmiting) => {
              let newSlides = slides
              newSlides[openDialog.slide]["data_points"][openDialog.data_point]["props"] = values
              setSlides([...newSlides])
              setDisabled(false)
              setOpenDialog({open: false, variant: "",  slide: 0, data_point: 0})
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (             
              <Grid container spacing={2}> 
                <Grid item xs={12}>
                  <TextField 
                    fullWidth
                    variant="outlined" margin="dense"
                    label="Opciones"
                    placeholder="separar las opciones con una coma"
                    value={values.options.join(",")}
                    onChange={(e) => setFieldValue(`options`,e.target.value.split(","))}/>                    
                </Grid>
                <Grid xs={6} item>
                  <TextField 
                    fullWidth
                    variant="outlined" margin="dense"
                    label="Siguiente pregunta"
                    name="next_index"
                    value={values.next_index}
                    onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                  <Button className="kt-login__btn-primary" color="primary" variant="contained" type="submit" onClick={handleSubmit} style={{float: "right"}}>Guardar</Button>
                </Grid>
              </Grid>          
            )}
          </Formik>
        )
      default:
        return (
          <Formik 
            initialValues={{
              ...slides[openDialog.slide]["data_points"][openDialog.data_point]["props"]
            }}
            onSubmit={(values,setSubmiting) => {
              let newSlides = slides
              newSlides[openDialog.slide]["data_points"][openDialog.data_point]["props"] = values
              setSlides([...newSlides])
              setDisabled(false)
              setOpenDialog({open: false, variant: "",  slide: 0, data_point: 0})
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (             
              <Grid container spacing={2}> 
                <Grid item xs={12}>
                  <TextField 
                    fullWidth
                    label="Mask"
                    variant="outlined" margin="dense"
                    value={values.mask}
                    name="mask"
                    onChange={handleChange}/>                  
                </Grid>
                <Grid item xs={12}>
                  <Button className="kt-login__btn-primary" color="primary" variant="contained" type="submit" onClick={handleSubmit} style={{float: "right"}}>Guardar</Button>
                </Grid>
              </Grid>          
            )}
          </Formik> )         
    }
  }
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#EEE5FF" : "transparent",
    borderRadius: 5,
    padding: 8,
    width: "100%"
  });
  
  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });

  const reorderDataPoints = (index, mIndex, endIndex) => {
    let newMechs = slides
    
    const result = Array.from(newMechs[index]["data_points"]);
    const [removed] = result.splice(mIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((n,i) => {
      result[i]["display_index"] = i+1;
    })

    newMechs[index]["data_points"] = result;
    
    setSlides([...newMechs])
    setDisabled(false)
  }

  const onDragEnd = (result, index) => {
    if (!result.destination) {
      return;
    }else {
      reorderDataPoints(index,result.source.index, result.destination.index)
    }
  }

  const renderSlides = () => {
    let nSlides = slides;
    if (should_sort){
      setShouldSort(false)
      nSlides = slides.sort((a,b) => { return parseInt(a.display_index) - parseInt(b.display_index) })
    }
    return nSlides.map((slide, i) => {
      let contextArray = slide.context.split(" ")
      let rexp = /\{(.*?)\}/

      return (
        <div key={slide.id}>
          <Paper style={{marginBottom:20,padding:8, fontFamily: "Roboto Medium"}} elevation={5}>
            <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
              <Grid container item xs={12} justify="space-between">
                <div>
                  <Typography variant="h6">
                    Pregunta {`${i + 1}`}
                  </Typography>
                </div>
                <div>
                  <FormControl variant="outlined" margin="dense">
                    <InputLabel>Paso en cotizador</InputLabel>
                    <OutlinedInput
                      type="number" 
                      value={slide.current_step}    
                      label="Paso en cotizador"                   
                      onChange={e => handleChange(e.target.value, i, "current_step")}
                      endAdornment={<InputAdornment position="end">/ {total_quote_steps}</InputAdornment>}/>                      
                  </FormControl>
                </div>
                <div>
                  <Button
                    onClick={() => setAlert({
                      title: "Eliminar pregunta",
                      message: "¿Estás seguro que deseas eliminar esta pregunta?",
                      btn_msg: "Eliminar",
                      action: () => deleteSlide(i)
                    })}
                    disabled={slides.length < 2 || (!slide.head && !slide.context)}
                    variant="contained"
                    color="secondary">
                    <Delete color="inherit" size={18} style={{marginRight:10}}/>
                    Eliminar pregunta
                  </Button>
                </div>
              </Grid>             
              <Grid item xs={12} style={{display: "none"}}>
                <TextField
                  type="number" 
                  fullWidth
                  variant="outlined" margin="dense"
                  label="Index"
                  name="display_index"
                  value={i}                    
                  onChange={e => handleChange(e.target.value, i, "display_index")}
                  />
              </Grid>
              <Grid item xs={12} style={{ position: "relative" }}>                  
                <TextField
                  fullWidth
                  variant="outlined" margin="dense"
                  label="Título"
                  name="head"
                  value={slide.head}                    
                  onChange={e => handleChange(e.target.value, i, "head")}
                />
              </Grid>
              <Grid item xs={12} style={{ position: "relative" }}>            
                <TextField
                  fullWidth
                  variant="outlined" margin="dense"
                  label="Subtítulo"
                  name="context"
                  value={slide.context}
                  onChange={e => handleChange(e.target.value, i, "context")}
                />
              </Grid>  
              <Grid item xs={12}>
                <Typography variant="h6">Data Points</Typography>
              </Grid>        
              <Grid item xs={12}>
                <DragDropContext onDragEnd={(result) => onDragEnd(result,i)}>
                  <Droppable droppableId={`droppable_${i}`}>
                    {(provided,snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                          {
                            slide.data_points.map((mc,j) => (
                              <Draggable key={`data_point_${j}`} draggableId={`data_point_${j}`} index={j}>
                                {(provided,snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      provided.draggableProps.style
                                    )}>
                                      <Grid container item xs={12} key={`data_point_${j}`} spacing={1}>
                                        <Grid container item xs={10} spacing={1}>
                                          <Grid item xs={6}>
                                          <TextField
                                              id={`name_${i}_${j}`}
                                              fullWidth
                                              variant="outlined" margin="dense"
                                              label="Nombre"
                                              name="name"
                                              value={mc.name}                    
                                              onChange={ev => onDataPointChange(i, j, ev.target.value, "name")}
                                            />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <TextField
                                              id={`key_${i}_${j}`}
                                              fullWidth
                                              label="Key"
                                              variant="outlined" margin="dense"
                                              value={mc.key}
                                              onChange={ev => onDataPointChange(i, j, ev.target.value, "key")}
                                              error={mc.key === ""}
                                            />
                                          </Grid>                                        
                                          <Grid item xs={12}>
                                            <TextField
                                              select
                                              fullWidth
                                              label="Mecanismo de captura"
                                              variant="outlined" margin="dense"
                                              name="capture_mechanism"
                                              value={mc.capture_mechanism.key}
                                              onChange={ev => onDataPointChange(i, j, ev.target.value, "capture_mechanism")}
                                              error={verifyDefault(mc.props)}
                                              helperText={verifyDefault(mc.props) ? "Configuración vacía" : ""}
                                            >
                                              {capture_mechanisms.data.map(op => (
                                                <MenuItem key={`MECH_${op.key}`} value={op.key}>{op.name}</MenuItem>
                                              ))}
                                            </TextField>
                                          </Grid>                    
                                          <Grid item xs={12}>
                                            <Button className="btn btn-info kt-login__btn-primary" style={{float:"right"}} onClick={() => setOpenDialog({open: true, variant: mc.capture_mechanism.key, slide: i, data_point: j})}>Configurar</Button>                    
                                          </Grid>              
                                        </Grid>
                                        <Grid container item xs={2} spacing={1} direction="row" justify="center" alignItems="center">
                                          <IconButton onClick={() => mc.id ? deleteDataPoint(deleteDatapoint,{id: mc.id, i:i, j: j}) : deleteDatapoint(i,j)}>
                                            <Delete/>
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                  </div>
                                )}
                              </Draggable>
                            ))
                          }
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>  
              <Grid item xs={12}>
                <Button
                  onClick={() => addNewDataPoint(i)}
                  variant="contained"
                  color="primary">
                  Agregar Data Point
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="column">
                <FormControlLabel 
                  control={<Checkbox checked={slide.finish} name="finish" onChange={e => handleChange(e.target.checked,i,"finish")}/>}
                  label="Terminar cotización después de esta pregunta"/>
                  {
                    slide.finish ? 
                    <FormControlLabel 
                      control={<Checkbox checked={slide.additional} name="additional" onChange={e => handleChange(e.target.checked,i,"additional")}/>}
                      label="Formulario adicional"/> : ""
                  }
              
                </Grid>
              </Grid>             
            </Grid>
          </Paper>
          {
            i < slides.length - 1 ? 
            <center>
              <Divider style={{marginBottom:20, width: "50%"}}/>
            </center>
            : ""
          }        
        </div>
      )
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog fullWidth maxWidth="md" open={openDialog.open} onClose={() => setOpenDialog({open: false, variant: "",  slide: 0, data_point: 0})}>
        <DialogTitle>Configuración</DialogTitle>
        <DialogContent>
          {openDialog.open ? renderConfigration(openDialog.variant) : ""}
        </DialogContent>        
      </Dialog>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={3}>
          <Paper style={{marginBottom:20,padding:8, fontFamily: "Roboto Medium"}} elevation={5}>
            <Typography variant="h6">
              Pasos totales del cotizador
            </Typography>            
            <TextField
              type="number" 
              fullWidth
              variant="outlined"
              label=""
              name="total_quote_steps"
              value={total_quote_steps}                    
              onChange={e => setTotalQuoteSteps(e.target.value)}
              className="pl-0 pt-2"/>
          </Paper>
          <Paper elevation={5} style={{ padding:20 }}>
            <Typography variant="h6">
                Datos a recopilar
            </Typography>
            <ul className="pl-0 pt-2">
              {slides.map((slide) => {

                return (
                  slide.data_points.map(dp => {
                    return <li key={dp.key} className="text-truncate mb-2" style={{ textDecoration: "line-through", fontFamily: "Roboto", fontWeight: 500 }}>{dp.key}</li>
                  })
                )
              })}
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={9}>          
          {renderSlides()}
          <center>
            <Button
              onClick={() => addNewSlide()}
              variant="contained"
              color="primary">
              <AddCircle color="inherit" size={18} style={{marginRight:10}}/>
              Agregar Pregunta
            </Button>
          </center>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

const dispatchToProps = (dispatch) => ({
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(null, dispatchToProps)(IQQuestionary)