import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Delete, Publish } from '@material-ui/icons';
import { Formik } from 'formik';
import { setAlert } from '../../../../redux/duck/alert.duck';
import { TextField, Grid, Button, Typography, IconButton } from '@material-ui/core';

const PageDownloadable = ({ page_downloadable, setterFunction, i, deletePageDownloadable, setAlert, deleted}) => {    
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...page_downloadable
            }}
        >
            {({values}) => (
                <Grid container spacing={1} alignItems="flex-end" style={{ marginBottom: "2vh" }}>                    
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={values.name}
                            onChange={(e) => setterFunction(`page_downloadables[${i}]['name']`,e.target.value)}    
                        />
                    </Grid>
                    <Grid item xs={11} sm={3}>
                    <div className="d-flex align-items-center">
                        <input
                            hidden
                            type="file"
                            id={`contained-button-file_${i}`}
                            onChange={e => setterFunction(`page_downloadables[${i}]['file']`, e.target.files[0])}/>
                        <label htmlFor={`contained-button-file_${i}`}>
                        <Button variant="contained" color="secondary" component="span" startIcon={<Publish />}>
                            Seleccionar
                        </Button>
                        </label>
                        <Typography className="ml-3" style={{wordWrap:"break-word", overflow: "auto"}}>{page_downloadable.file !== null && page_downloadable.file !== undefined ? page_downloadable.file.name : page_downloadable.file_url ? page_downloadable.file_url.split("/").pop() : ""}</Typography>
                    </div>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <IconButton onClick={() => {
                            values.file || values.id
                            ? setAlert({
                                title: "Eliminar descargable",
                                message: "¿Estás seguro que deseas eliminar el descargable?",
                                btn_msg: "Eliminar",
                                action: () => deletePageDownloadable(i, setterFunction,deleted)
                            })
                            : deletePageDownloadable(i, setterFunction,deleted)
                        }}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

const mapStateToProps = store => ({})

const mapDispatchToProps = dispatch => ({
  setAlert: bindActionCreators(setAlert, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageDownloadable)