import React, { useState, useEffect, useRef } from 'react'
import { Tabs, Tab, Paper, CircularProgress } from '@material-ui/core'
import { ArrowBack, Save } from '@material-ui/icons'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IQQuestionary from "./IQQuestionary"
import IQResults from "./IQResults"

import * as insQuoteDUCK from "../../../redux/duck/InsuranceQuote.duck"
import { updateQuoteSlides } from '../../crud/api/insurance_quote.crud';

const InsuranceQuote = ({ quote, quoteActions , capture_mechanisms, insurance_type }) => {
  const [tab, setTab] = useState(0)
  const [slides, setSlides] = useState(quote.slides.sort((a,b) => { return parseInt(a.display_index) - parseInt(b.display_index) }))
  const [total_quote_steps,setTotalQuoteSteps] = useState(quote.total_quote_steps)
  const [should_sort,setShouldSort] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleted,setDeleted] = useState([])
  const history = useHistory()
  const [result, setResult] = useState({
    title: "",
    subtitle: "",
    display_icon: insurance_type.icon_1,
    display_text: "",
    button_text: "",
    data_points: [],
    file: null,
  })
  const [disabled,setDisabled] = useState(true)
  const isInitialMount = useRef(true);

  //Dispatch effect when data is loaded
  useEffect(() => {
    let nResult = {
      display_icon: insurance_type.results_display_icon,
      title: insurance_type.results_title,
      subtitle: insurance_type.results_subtitle,
      display_text: insurance_type.results_product_display_text,
      button_text: insurance_type.results_button_display_text,
      data_points: insurance_type.quote_rule?.fields ? insurance_type.quote_rule.fields.split(',') : [],
      description: insurance_type.results_description,
      file: null
    }
    setResult(nResult)
  }, [insurance_type]);

  const renderComponent = () => {
    let component

    switch (tab) {
      case 0:
        component = <IQQuestionary slides={slides} setSlides={setSlides} capture_mechanisms={capture_mechanisms} setDeleted={setDeleted} deleted={deleted} 
                      setShouldSort={setShouldSort} should_sort={should_sort} total_quote_steps={total_quote_steps} setTotalQuoteSteps={setTotalQuoteSteps} setDisabled={setDisabled}/>
        break;
      case 1:
        let dataPoints = []
        slides.map(s => {
          s.data_points.map(dp => {
            dataPoints.push(dp.key)
          })
        })
        component = <IQResults dataPoints={dataPoints} result={result} setResult={setResult} setDisabled={setDisabled}/>
        break;

      default:        
      component = <IQQuestionary slides={slides} setSlides={setSlides} capture_mechanisms={capture_mechanisms} setDeleted={setDeleted} deleted={deleted} 
                    setShouldSort={setShouldSort} should_sort={should_sort} total_quote_steps={total_quote_steps} setTotalQuoteSteps={setTotalQuoteSteps} setDisabled={setDisabled}/>
        break;
    }
    return component
  }

  const resetStates = () => {
    setSlides(quote.slides.sort((a,b) => { return parseInt(a.display_index) - parseInt(b.display_index) }))
    setTotalQuoteSteps(quote.total_quote_steps)
    setResult({
      ...result,
      file: null
    })
    setDeleted([])
    setShouldSort(true)
    setLoading(false)
  }

  useEffect(() => {
    if(isInitialMount.current){
      isInitialMount.current = false
    } else {
      resetStates();
    }
    
  }, [quote]);

  const reducer = (accumulator, currentValue) => {
    if(!accumulator.find(obj => obj.context === currentValue.context && obj.head === currentValue.head)){
      accumulator.push(currentValue);
    }
    return accumulator;
  };

  return (
    <>      
      <div className="pb-4 d-flex justify-content-end align-items-end" style={{width: "100%", marginBottom:10}}>
        <div id="secret_subtitle" style={{display: "none"}}>Cotizador - {quote.name}</div>
        <div className="d-flex">
            <button className="btn btn-secondary" onClick={() => { history.push(`/insurance_types`); quoteActions.resetInsQuote()}}><ArrowBack /> Regresar</button>
            <button
              type="submit"
              className="btn btn-info ml-2 d-flex"
              onClick={() => {
                setLoading(true);
                updateQuoteSlides(quoteActions.updateInsQuoteSlides,{insurance_type_id: quote.id, slides: slides.reduce(reducer,[]), deleted: deleted, total_quote_steps: total_quote_steps, results: result})
                .then(() => {
                  setDisabled(true);
                })
                .catch(() => setLoading(false))
                
              }}
              disabled={disabled}
            >
              <Save color="inherit" size={18} style={{marginRight:10}}/>
              Guardar
              {loading && <CircularProgress color="inherit" size={18} style={{ marginLeft: 10 }} />}
            </button>
        </div>
      </div>
      <Paper className="tab-selector" style={{ position: "relative", alignItems: "center" }}>
        <Tabs
          value={tab}
          onChange={(e, value) => {
            setTab(value)
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Cuestionario" />
          <Tab label="Resultados" />
        </Tabs>
      </Paper>
      <div style={{ padding: 20, marginBottom: 5 }}>
        {renderComponent()}
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  quote: store.ins_quote.ins_quote,
  capture_mechanisms: store.ins_quote.capture_mechanisms,
  insurance_type: store.ins_types.insurance_type.data,
})

const mapDispatchToProps = dispatch => ({
  quoteActions: bindActionCreators(insQuoteDUCK.actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceQuote)
