import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appDuck from '../../../redux/duck/app.duck';

class configWrapper extends Component {
  constructor(props){
    super(props);
    props.appActions.ShowLoader();
  }


  componentDidMount() {
    const {appActions} = this.props
    setTimeout(() => {
      appActions.HideLoader();
    }, 700)
  }

  render() {
    return (
      <div>
        
      </div>
    )
  }
}

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch)
});

export default connect(null, dispatchToProps)(configWrapper)