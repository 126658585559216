import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export const defaultBlog = {
  title: "",
  context: "",
  publish_date: Date(),
  image: null,
  og_image: null,
  twitter_image: null,
  body: "",
}

export const actionType = {
  GetBlogs: '[BLOG] GET_BLOGS',
  GetBlog: '[BLOG] GET_BLOG',
  UpdateBlog: '[BLOG] UPDATE_BLOGS',
  DeleteBlog: '[BLOG] DELETE_BLOGS'
}

const initialState = {
  blogs: { data: [], isFetched: false },
  blog: defaultBlog
}

export const reducer = persistReducer(
  { storage, key: 'blog', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.GetBlogs: {
        return { ...state, blogs: { data: action.payload.items, count: action.payload.total, page: action.payload.page -= 1, pages: action.payload.pages, rowsPerPage: action.payload.per_page, isFetched: true } }
      }

      case actionType.UpdateBlog: {
        let newData = state.blogs.data
        const index = state.blogs.data.findIndex(x => x.slug === action.payload.slug)

        if (index === -1) {
          newData = [action.payload, ...state.blogs.data]
        } else {
          newData = state.blogs.data
          newData[index] = action.payload
        }

        return { ...state, blogs: { ...state.blogs, data: newData } }
      }

      case actionType.GetBlog: {

        return { ...state, blog: action.payload }
      }

      case actionType.DeleteBlog: {
        let newData = state.blogs.data.filter((x) => x.slug !== action.payload.slug )
        return {...state, blogs: {...state.blogs, data: newData}, isFetched: true}
      }

      default: return state

    }
  }
)

export const actions = {
  setBlogs: data => ({ type: actionType.GetBlogs, payload: data }),
  setBlog: data => ({ type: actionType.GetBlog, payload: data }),
  updateBlogs: data => ({ type: actionType.UpdateBlog, payload: data }),
  deleteBlog: data => ({ type: actionType.DeleteBlog, payload: data }),
}