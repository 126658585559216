/* eslint-disable array-callback-return */
import { INSURANCE_TYPES_URL } from "../helpers/routes";
import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import notice from "../../../utils/notice"

export const getInsuranceTypes = (setterFunction, page = 1,search = "") => {
    return fetch(`${INSURANCE_TYPES_URL}?page=${page}&search=${search}`,getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error cargando los tipos de seguro")
        throw e
    })
}

export const getInsuranceType = (setterFunction,slug) => {
    return fetch(`${INSURANCE_TYPES_URL}/show_by_slug?slug=${slug}`,getParams('GET'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error cargando el tipo de seguro")
        throw e
    })
}

export const updateInsuranceType = (setterFunction,params) => {
    var formdata = new FormData();
    Object.keys(params).map(key => {
        if(key === "testimonials"){
            params[key].forEach((element,index) => {
                Object.keys(element).map(k => {
                    if(k === "person_avatar"){
                        if(typeof element[k] === "object")
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }else {
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }
                })                
            });
        }else if(key === "deleted_fun_facts" || key === "deleted_testimonials" || key === "fun_facts" || key === "deleted_downloadables"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "icon_1"||key === "icon_2"||key === "icon_3" || key === "fun_facts_image"){
            if(params[key] === null){
                formdata.append(key, "")
            }else {
                if(typeof params[key] === "object")
                formdata.append(key, params[key])
            }
        }else if(key === "page_sections"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "page_downloadables"){
            params[key].forEach((element,index) => {
                Object.keys(element).map(k => {
                    if(k === "file"){
                        if(typeof element[k] === "object")
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }else {
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }
                })                
            });
        }else {
            formdata.append(key,params[key])
        }
    })
    return fetch(`${INSURANCE_TYPES_URL}/update`,getMultipartParams('PUT',formdata))
    .then(res => handleResponse(res))
    .then(json => {
        setterFunction(json);
        notice("Tipo de seguro actualizado","success")
    })
    .catch(e => {
        console.log(e)
        notice("Error actualizando tipo de seguro")
        throw e
    })
}

export const createInsuranceType = (setterFunction,params) => {
    var formdata = new FormData();
    Object.keys(params).map(key => {
        if(key === "testimonials"){
            params[key].forEach((element,index) => {
                Object.keys(element).map(k => {
                    if(k === "person_avatar"){
                        if(typeof element[k] === "object")
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }else {
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }
                })                
            });
        }else if( key === "fun_facts"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "icon_1"||key === "icon_2"||key === "icon_3" || key === "fun_facts_image"){
            if(params[key] === null){
                formdata.append(key, "")
            }else {
                if(typeof params[key] === "object")
                formdata.append(key, params[key])
            }
        }else if(key === "page_sections"){
            formdata.append(key,JSON.stringify(params[key]))
        }else if(key === "page_downloadables"){
            params[key].forEach((element,index) => {
                Object.keys(element).map(k => {
                    if(k === "file"){
                        if(typeof element[k] === "object")
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }else {
                        formdata.append(`${key}[${index}][${k}]`, element[k])
                    }
                })                
            });
        }else{
            formdata.append(key,params[key])
        }
    })
    return fetch(`${INSURANCE_TYPES_URL}`,getMultipartParams('POST',formdata))
    .then(res => handleResponse(res))
    .then(json => {
        setterFunction(json);
        notice("Tipo de seguro creado con éxito","success")
    })
    .catch(e => {
        console.log(e)
        notice("Error creando tipo de seguro")
        throw e
    })
}

export const deleteInsuranceType = (setterFunction,slug) => {
    return fetch(`${INSURANCE_TYPES_URL}/${slug}`,getParams('DELETE'))
    .then(res => handleResponse(res))
    .then(json => setterFunction(json))
    .catch(e => {
        console.log(e)
        notice("Error eliminando el tipo de seguro")
        throw e
    })
}