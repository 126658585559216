import React, { Suspense, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { makeStyles, CircularProgress, Backdrop } from "@material-ui/core";
import {connect} from  'react-redux';
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import UserWrapper from "./pages/User/UserWrapper";
import InsuranceTypeWrapper from "./pages/InsuranceType/InsuranceTypeWrapper";
import ConfigWrapper from "./pages/Config/ConfigWrapper";
import LifeStageWrapper from "./pages/LifeStage/LifeStageWrapper";
import BlogWrapper from "./pages/Blog/BlogWrapper";
import QuoteWrapper from "./pages/Quote/QuoteWrapper";

import * as quotesDUCK from "../redux/duck/quotes.duck";
import { getNewQuotes } from '../app/crud/api/quotes.crud';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important'
  },
  circular:{
    color: 'black !important'
  }
}));

function BasePage({app, quoteActions}) {
  useEffect(() => {
    getNewQuotes(quoteActions.setNewQuotes)
  }, [])

  const classes = useStyles();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Backdrop className={classes.backdrop} open={app.showLoader}>
        <CircularProgress
          className={classes.circular}
        />
      </Backdrop>
      <Switch>
        {
          /* Redirect from root URL to /users. */
          <Redirect exact from="/" to="/users" />
        }
        <ContentRoute path="/users" component={UserWrapper} />
        <ContentRoute path="/quotes" component={QuoteWrapper} />
        <ContentRoute path="/insurance_types" component={InsuranceTypeWrapper} />        
        <ContentRoute path="/life_stages" component={LifeStageWrapper} />
        <ContentRoute path="/blog" component={BlogWrapper} />
        <ContentRoute path="/config" component={ConfigWrapper} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

const mapStateToProps = (store) => ({
  app: store.app
})

const mapDispatchToProps = dispatch => ({
  quoteActions: bindActionCreators(quotesDUCK.actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BasePage)