/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import { Paper, MuiThemeProvider, useMediaQuery, Button, Avatar, Tooltip } from '@material-ui/core'
import { bindActionCreators } from "redux";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";

import { tableMuiThemeNoToolbar } from "../../table/table_styles"

import * as appDuck from '../../../redux/duck/app.duck';
import * as usersDuck from '../../../redux/duck/users.duck';
import { getUsers } from '../../crud/api/users.crud';
import { Edit, VpnKey } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PasswordReset from './PasswordReset';

const User = ({ appActions, users, userActions }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [email, setEmail] = useState("")

  const userEvents = {
    newUser: () => history.push("/users/new"),
    editUser: (id) => history.push(`/users/${id}/edit`)
  }

  const getSingleUser = (id) => {
    appActions.ShowLoader()
    let user = users.data.filter(x => x.id === id)[0] 
    userActions.setUser(user);
    setTimeout(() =>{
      userEvents.editUser(id)
      appActions.HideLoader()
    },1000)
  }
  
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'profile_picture_thumb',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Avatar src={value} />
        )
      }
    },
    {
      name: 'first_name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'last_name',
      label: 'Apellido',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'email',
      label: 'Correo',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>{value}</>
          )
        }
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => (
          <div className="d-flex">
            <Tooltip title="Editar usuario" placement="top">
              <a className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary" onClick={() => getSingleUser(value)}>
                <Edit />
              </a>
            </Tooltip>
            <Tooltip title="Editar contraseña" placement="top">
              <a className="btn btn-icon btn-sm btn-light btn-hover-warning text-warning  mx-3" onClick={() => setEmail(meta.rowData[4])}>
                <VpnKey />
              </a>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const options = {
    serverSide: true,
    responsive: 'standard',
    rowsPerPageOptions: [],
    rowsPerPage: users.rowsPerPage,
    page: users.page,
    count: users.count,
    search: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
    downloadOptions: {
      filename: "users.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: (mPage) => {
      appActions.ShowLoader()
      getUsers(userActions.setUsers, mPage +1)
      .then(() => appActions.HideLoader())
      .catch(() => appActions.HideLoader())
    }
  };

  return (
    <>
      <div className="pb-4 d-flex justify-content-end align-items-end">
        <Button variant="contained" color="primary" onClick={() => { userActions.setUser(usersDuck.defaultUser);userEvents.newUser()}}>Nuevo usuario</Button>
      </div>
      <Paper>
        <MuiThemeProvider theme={tableMuiThemeNoToolbar()}>
          <MUIDataTable
            title=""
            columns={columns}
            data={users.data}
            options={options}
          />
        </MuiThemeProvider>
      </Paper>
      <PasswordReset email={email} setEmail={setEmail} />
    </>
  )
}

const mapStateToProps = store => {
  return ({
    users: store.user.users
  })
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appDuck.actions, dispatch),
  userActions: bindActionCreators(usersDuck.actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(User)
