import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {

        return { ...action.payload };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.clear()
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: data => ({ type: actionTypes.Login, payload: data }),
  logout: () => ({ type: actionTypes.Logout }),
};
