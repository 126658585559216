import { getMultipartParams, getParams, handleResponse } from "../helpers/validate"
import { BLOGS_URL } from "../helpers/routes"
import notice from "../../../utils/notice"
import jsonToFormData from '@ajoelp/json-to-formdata';

export const getBlogs = (setterFunction, page = 1, search = "") => {
  return fetch(`${BLOGS_URL}?page=${page}&search=${search}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error cargando blogs")
    console.log(e.errors)
    throw e
  })
}

export const getBlog = (setterFunction, slug) => {
  return fetch(`${BLOGS_URL}/show?slug=${slug}`, getMultipartParams('GET'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error cargando blog")
    console.log(e.errors)
    throw e
  })
}

export const saveBlog = (setterFunction, data) => {
  
  return fetch(BLOGS_URL, getMultipartParams('POST', jsonToFormData(data,{excludeNull:true})))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Blog guardado", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error guardando blog")
    console.log(e.errors)
    throw e
  })
}

export const updateBlog = (setterFunction, data, id) => {
  
  return fetch(`${BLOGS_URL}/update?id=${id}`, getMultipartParams('PUT',jsonToFormData(data,{excludeNull:true})))
  .then(res => handleResponse(res))
  .then(json => {
    notice("Blog actualizado", "success")
    return setterFunction(json)
  })
  .catch(e => {
    notice("Error guardando blog")
    console.log(e.errors)
    throw e
  })
}

export const deleteBlog = (setterFunction, slug) => {
  return fetch(`${BLOGS_URL}/${slug}`, getParams('DELETE'))
  .then(res => handleResponse(res))
  .then(json => setterFunction(json))
  .catch(e => {
    notice("Error eliminando blog")
    console.log(e.errors)
    throw e
  })
}