import React, {useState, useEffect} from 'react'
import { Button, Card, CardContent, Checkbox, Chip, Divider, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select, TextField, Typography, useMediaQuery } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Publish } from '@material-ui/icons';
import { connect } from 'react-redux';
import HTMLEditor from '../../../utils/HTMLEditor';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#673ab778 !important",
    color: "white !important"
  }
}));

const IQResults = ({ dataPoints, result, setResult, insurance_type, setDisabled }) => {
  const isDesktop = useMediaQuery("(min-width: 600px)")
  const classes = useStyles()

  const handleChange = ({target}) => {
    setResult({...result, [target.name]: target.value})
    setDisabled(false)
  }

  const setFieldValue = (field, value) => {
    setResult({...result, [field]: value})
    setDisabled(false)
  }
  const [filename, setFileName] = useState('');
  useEffect(() =>{
    let file = '';
    if(result.file === null){
      const fileName = insurance_type.quote_rule?.file_url || '';
      if(fileName.match(/.csv$/)){
        file = fileName.split("/");
        file = file[(file.length-1)];
      }
    } else file = result.file.name
      setFileName(file);
      setDisabled(false)
  }, [insurance_type.quote_rule, result.file])

  return (
    <>
      <Paper elevation={5} className="px-4 pt-4 pb-8" style={{ fontFamily: "Roboto Medium" }}>
        <Grid container spacing={2} direction={isDesktop ? "row" : "column"} alignItems="center" justify="center">
          <Grid container item xs={12} sm={6} spacing={2} className="px-7">
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Titulo"
                name="title"
                value={result.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Subtitulo"
                name="subtitle"
                value={result.subtitle}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className="text-muted">Icono</Typography>                             
              <div className="d-flex justify-content-center">
                <ToggleButtonGroup exclusive value={result.display_icon} onChange={(e, newValue) => setFieldValue("display_icon", newValue)}>
                  <ToggleButton classes={{...classes}} value={insurance_type.icon_1}>
                    <img src={insurance_type.icon_1} alt="icon_1" width="100px" />
                  </ToggleButton>
                  {insurance_type.icon_2 && <ToggleButton classes={{...classes}} value={insurance_type.icon_2}>
                    <img src={insurance_type.icon_2} alt="icon_2" width="100px" />
                  </ToggleButton>}
                  {insurance_type.icon_3 && <ToggleButton classes={{...classes}} value={insurance_type.icon_3}>
                    <img src={insurance_type.icon_3} alt="icon_3" width="100px" />
                  </ToggleButton>}
                </ToggleButtonGroup>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Texto a mostrar"
                name="display_text"
                value={result.display_text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Texto del botón"
                name="button_text"
                value={result.button_text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <HTMLEditor content={result.description} setterFunction={setFieldValue} field={"description"}/>  
            </Grid>
          </Grid>

          <Divider orientation={isDesktop ? "vertical" : "horizontal"} flexItem={Boolean(isDesktop)} className={isDesktop ? "my-10" : "mx-20 my-5"} />

          <Grid container item xs={12} sm={6} spacing={2} direction="column" alignItems="center" justify="center">
            <Typography variant="h4" className="font-weight-bolder">{result.title}</Typography>
            <Typography variant="subtitle1" className="font-weight-bolder">{result.subtitle}</Typography>
            <Card variant="outlined" style={{ width: "80%", maxWidth: 350, textAlign: "center", borderRadius: 15 }}>
              <CardContent>
                <img src={result.display_icon} alt="icon" width="110px"/>
                <Typography>{result.display_text}</Typography>
                <Typography variant="h4" className="text-info font-weight-bolder">$ 800.00</Typography>
                <Button color="primary" variant="contained">{result.button_text}</Button>
              </CardContent>
            </Card>
            <div style={{fontFamily:"Poppins",padding:10}} dangerouslySetInnerHTML={{__html: result.description}}/>
          </Grid>
        </Grid>
      </Paper>
      
      <div style={{ textAlign: "-webkit-center" }} className="my-8">
        <Divider style={{ maxWidth: "50%" }} />
      </div>

      <Paper elevation={5} className="px-8 py-8" style={{ fontFamily: "Roboto Medium" }}>
          <Typography variant="h6">Estrategia de cálculo</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Data points utilizados</InputLabel>
                <Select
                  multiple
                  name="data_points"
                  label="Data Points utilizados"
                  value={result.data_points}
                  onChange={handleChange}
                  renderValue={selected => {
                    return (
                    <div className="d-flex flex-wrap">
                      {selected.map(value => (
                        <Chip key={value} label={value} color="primary" className="mr-2" size="small" />
                      ))}
                    </div>
                  )}}>
                {dataPoints.map((data, i) => (
                  <MenuItem value={data} key={`OPTION_${data}`}>
                    <Checkbox color="primary" checked={result.data_points.includes(data)} />
                    <ListItemText primary={data} />
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="d-flex align-items-center">
              <input
                hidden
                type="file"
                accept=".csv"
                id="contained-button-file"
                onChange={e => setFieldValue("file", e.target.files[0])}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span" startIcon={<Publish />}>
                  Seleccionar
                </Button>
              </label>
              <Typography className="ml-3">{filename}</Typography>
            </Grid>
          </Grid>
          {/* <ToggleButtonGroup className="flex-column py-2 px-4" style={{ width: "100%" }} exclusive value={result.strategy} onChange={(e, newValue) => setFieldValue("strategy", newValue)}>
            {[1,2,3,4,5].map((item) => (
              <ToggleButton key={item} classes={{...classes}} value={item} className="mb-3" style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <Grid container spacing={2} className="d-flex align-items-center">
                  <Grid item xs={2} className="d-flex align-items-center">
                    <img src="https://foroalfa.org/imagenes/ilustraciones/estrategia.jpg" alt="prev" width="50" height="50" className="pr-3" />
                    <Typography className="font-weight-bold">CSV</Typography>
                  </Grid>
                  <Grid item xs={10} className="d-flex align-items-center">
                    <Typography className="font-weight-bold">Estrategia mediante un archivo CSV</Typography>
                  </Grid>
                </Grid>
              </ToggleButton>
            ))}
          </ToggleButtonGroup> */}
      </Paper>
    </>
  )
}

const mapStateToProps = store => {
  return({
    insurance_type: store.ins_types.insurance_type.data
  })
}

export default connect(mapStateToProps, null)(IQResults)
