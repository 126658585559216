/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {  MuiThemeProvider, Paper, useMediaQuery, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { Delete, Edit } from '@material-ui/icons';
import { tableMuiTheme } from '../../table/table_styles';
import MUIDataTable from 'mui-datatables';

import * as appDuck from '../../../redux/duck/app.duck';
import * as lifeStageDuck from '../../../redux/duck/lifeStage.duck';
import { setAlert } from '../../../redux/duck/alert.duck'
import { getLifeStage, getLifeStages, deleteLifeStage } from '../../crud/api/life_stages.crud';

const LifeStage = ({ life_stages,lifeStageActions, appActions, setAlert }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery("(min-width: 960px)")
  const [query, setQuery] = useState("");

  const lifeStageEvents = {
    newLifeStage: () => history.push("/life_stages/new"),
    editLifeStage: (id) => history.push(`/life_stages/${id}/edit`)
  }

  const getSingleLifeStage = (id) => {
    appActions.ShowLoader()
    getLifeStage(lifeStageActions.setLifeStage,id)
    .then(() => {
      lifeStageEvents.editLifeStage(id)
      appActions.HideLoader()
    })
    .catch(() => appActions.HideLoader())
  }

  const removeLifeStage = (id) => {
    appActions.ShowLoader()
    deleteLifeStage(lifeStageActions.deleteLifeStage,id)
    .then(() => appActions.HideLoader())
    .catch(() => appActions.HideLoader())
  }

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "stage",
      label: "Etapa",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div className="d-flex">
            <a
              className="btn btn-icon btn-sm btn-light btn-hover-primary text-primary"
              onClick={() => getSingleLifeStage(value)}
            >
              <Edit />
            </a>
            <a
              className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger ml-3"
              onClick={() =>
                setAlert({
                  title: "Eliminar etapa de la vida",
                  message:
                    "¿Estás seguro que deseas eliminar la etapa de la vida?",
                  btn_msg: "Eliminar",
                  action: () => removeLifeStage(value),
                })
              }
            >
              <Delete />
            </a>
          </div>
        ),
      },
    },
  ];

  const options = {
    serverSide: true,
    responsive: 'standard',
    rowsPerPageOptions: [],
    rowsPerPage: life_stages.rowsPerPage,
    page: life_stages.page,
    count: life_stages.count,
    search: true,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRows: 'none',
    tableBodyMaxHeight: isDesktop ? "50em" : "357px",
    textLabels: {
      body: {
        noMatch: "No data"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
    downloadOptions: {
      filename: "life_stages.csv",
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayRowsOnly: true}
    },
    onChangePage: (mPage) => {
      appActions.ShowLoader()
      getLifeStages(lifeStageActions.setLifeStages, mPage +1,query)
      .then(() => appActions.HideLoader())
      .catch(() => appActions.HideLoader())
    },
    searchText: query,
    onSearchChange: (s) => {
      let search = s === null ? "" : s
      if (search.length >= 3 || search === "") {
        appActions.ShowLoader();
        getLifeStages(lifeStageActions.setLifeStages,1, search)
        .then(() => appActions.HideLoader())
        .catch(() => appActions.HideLoader());
      }
      setQuery(search)
    }
  };

  return (
    <>    
    <div className="pb-4 d-flex justify-content-end align-items-end">
      <Button variant="contained" color="primary" onClick={() => { lifeStageActions.setLifeStage(lifeStageDuck.defaultLifeStage);lifeStageEvents.newLifeStage()}}>Nueva etapa de vida</Button>
    </div>
    <Paper>
      <MuiThemeProvider theme={tableMuiTheme()}>
        <MUIDataTable
          title="LifeStages"
          columns={columns}
          data={life_stages.data}
          options={options}
        />
      </MuiThemeProvider>
    </Paper>
    </>
  )}

const mapStateToProps = store => {
  return ({
    life_stages: store.life_stage.life_stages
  })
}

const mapDispatchToProps = (dispatch) => ({
  lifeStageActions: bindActionCreators(lifeStageDuck.actions,dispatch),
  appActions: bindActionCreators(appDuck.actions, dispatch),
  setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(LifeStage)
