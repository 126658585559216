/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";

export function UserProfileDropdown() {
  const { user } = useSelector(state => state.auth);

  return (
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
        >
          <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Bienvenido,</span>
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.first_name} {user.last_name}
            </span>
            <span className="symbol symbol-35 symbol-light-info">            
              <span className="symbol-label font-size-h5 font-weight-bold">{user.first_name[0]}</span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <div
              className="d-flex align-items-center flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
          >
            <div className="symbol symbol-100 mr-3">
              <span className="symbol-label text-info font-weight-bold font-size-h4">{user.first_name[0]}</span>
              {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
            </div>
            <div className="d-flex flex-column">
              <div className="flex-grow-1 font-size-h5">
                {user.first_name} {user.last_name}
              </div>
              <div className="text-white text-muted">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}/>
                </span>
                  {user.email}
                </div>
                <Link to="/logout" className="btn btn-light-info btn-bold font-weight-bold mt-5">
                  Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
}
